// utils
import handleError from 'utils/handle-error';
import clearReduxStore from 'utils/clear-redux-store';
import callAzureFunctionAuth from 'utils/call-azure-function-auth';

const handleLogout = async () => {
  try {
    // invalidate cookies
    await callAzureFunctionAuth({
      url: `/auth-remove-access`,
      method: 'get'
    });
  } catch (error) {
    handleError(error);
  } finally {
    // clear redux store values
    clearReduxStore();

    // initiate logout action to all other tabs
    localStorage.setItem('isLoggedIn', false);
  }
};

// eslint-disable-next-line import/prefer-default-export
export { handleLogout };
