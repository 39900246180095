import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  hasUserIgnoredBannerForBookingPage: false
};

const triggerAlertBannerSlice = createSlice({
  name: 'triggerAlertBanner',
  initialState,
  reducers: {
    setIgnoreBannerForBookingPageDeletedCompany: (state) => {
      state.hasUserIgnoredBannerForBookingPage = true;
    }
  }
});

export const { setIgnoreBannerForBookingPageDeletedCompany } = triggerAlertBannerSlice.actions;

export default triggerAlertBannerSlice.reducer;
