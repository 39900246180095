import { lazy } from 'react';

// project imports
import Loadable from 'ui-component/Loadable';
import MinimalLayout from 'layout/MinimalLayout';

const PublicCreateBooking = Loadable(lazy(() => import('views/public-booking-pages/create-booking')));

// ==============================|| Public Booking ROUTING ||============================== //

const PublicBookingRoutes = {
  path: '/',
  element: <MinimalLayout />,
  children: [
    {
      path: '/booking-page/:bookingPageUid',
      element: <PublicCreateBooking />
    }
  ]
};

export default PublicBookingRoutes;
