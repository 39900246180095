// redux
import { useSelector } from 'react-redux';

// project constants
import { appConfigMeta as defaultAppConfig } from 'constants/appConfig';

/**
 * Gets the name of an entity.
 * @param {string} id Entity id (key)
 * @param {Object} config App config
 * @returns {string} Entity name (value) or id
 */
export const getEntityName = (id, config) => config?.entities?.[id] || defaultAppConfig?.entities?.[id] || id;

// same as above but as a hook which doesn't require passing appConfig as a parameter
export const useGetEntityName = (id) => {
  const appConfig = useSelector((state) => state.appConfig?.data);
  return getEntityName(id, appConfig);
};

/**
 * Gets the image URL of an entity.
 * @param {string} id Entity id (key)
 * @param {Object} config App config
 * @returns {string} Image URL (value) or id
 */
export const getImageUrl = (id, config) => {
  const { images } = config;

  if (images) {
    return images[id] || '/logo_placeholder1.png';
  }

  return id;
};
