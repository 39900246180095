import { lazy } from 'react';
import { Navigate } from 'react-router-dom';

// project imports
import Loadable from 'ui-component/Loadable';
import MinimalLayout from 'layout/MinimalLayout';
import userTypes from 'constants/userTypes';

const Login = Loadable(lazy(() => import('views/authentication/auth-components/Login')));
const Redirect = Loadable(lazy(() => import('views/authentication/auth-components/Redirect')));
const RequireLogin = Loadable(lazy(() => import('views/authentication/auth-components/RequireLogin')));

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const AuthenticationRoutes = {
  path: '/',
  element: <MinimalLayout />,
  children: [
    {
      path: '/',
      element: <Navigate to="/login" />
    },
    {
      path: '/login',
      element: <Login userType={userTypes.Admin} />
    },
    {
      path: '/login-manager',
      element: <Login userType={userTypes.Manager} />
    },
    {
      path: '/redirect',
      element: <Redirect />
    },
    {
      path: '*',
      element: <RequireLogin />
    }
  ]
};

export default AuthenticationRoutes;
