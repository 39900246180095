import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// MUI
import { Button, Chip, FormHelperText, OutlinedInput, Stack, Grid, Checkbox, Typography, FormControlLabel, Alert } from '@mui/material';

// utils
import isEmailValid from 'utils/validate-email';
import { useGetEntityName } from 'utils/entities';

// ====================================== || AddMultipleEmails || ====================================== //

const AddMultipleEmails = ({ recipients, setRecipients, inputWidth }) => {
  const bookingEntityName = useGetEntityName('booking');

  const [email, setEmail] = useState('');
  const [inputError, setInputError] = useState(null);
  const [consentGranted, setConsentGranted] = useState(true);
  const [recipientsCache, setRecipientsCache] = useState([]); // stores recipients so they can be restored if consent is revoked then granted again

  const addRecipient = () => {
    const newEmail = email.toLocaleLowerCase().trim();

    setInputError('');

    if (!isEmailValid(newEmail)) {
      setInputError('Please enter a valid email address');
      return;
    }
    if (recipients.includes(newEmail)) {
      setInputError('Email matching provided value already exists');
      return;
    }
    setRecipients((prev) => [...prev, newEmail]);
    setRecipientsCache((prev) => [...prev, newEmail]);
    setEmail('');
  };

  const removeRecipient = (emailToRemove) => {
    setRecipients(recipients.filter((email) => email !== emailToRemove));
    setRecipientsCache(recipientsCache.filter((email) => email !== emailToRemove));
  };

  useEffect(() => {
    if (!consentGranted) {
      setRecipients([]);
    } else {
      setRecipients(recipientsCache);
    }
  }, [consentGranted, recipientsCache, setRecipients]);

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Stack direction="row" gap={2}>
          <OutlinedInput
            error={inputError}
            sx={{ width: inputWidth || '100%' }}
            placeholder="Enter email"
            size="small"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <Button size="small" onClick={addRecipient}>
            Add
          </Button>
        </Stack>
      </Grid>
      <Grid item xs={12}>
        {inputError && (
          <FormHelperText sx={{ ml: 1 }} error>
            {inputError}
          </FormHelperText>
        )}
      </Grid>
      <Grid item xs={12}>
        {recipientsCache.length > 0 && (
          <Stack direction="row" gap={2} flexWrap="wrap">
            {recipientsCache.map((email) => (
              <Chip label={email} variant="outlined" onDelete={() => removeRecipient(email)} />
            ))}
          </Stack>
        )}
      </Grid>
      <Grid item xs={12} container direction="row" alignItems="center">
        <FormControlLabel
          control={
            <Checkbox
              name="consentEmail"
              color="primary"
              checked={consentGranted}
              onChange={(event) => setConsentGranted(event.target.checked)}
            />
          }
          label={
            <Typography>
              {`I confirm that the individuals represented by the above email addresses consent to receiving emails regarding this ${bookingEntityName},
              and that I have the authority to provide consent on their behalf.`}
            </Typography>
          }
        />
      </Grid>
      <Grid item xs={12}>
        {!consentGranted && <Alert severity="error">The above emails will not receive a message unless you grant consent</Alert>}
      </Grid>
    </Grid>
  );
};

AddMultipleEmails.propTypes = {
  recipients: PropTypes.array,
  setRecipients: PropTypes.func,
  inputWidth: PropTypes.any
};

export default AddMultipleEmails;
