import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  open: false,
  title: '',
  contentText: '',
  size: 'sm'
};

const alertSlice = createSlice({
  name: 'alert',
  initialState,
  reducers: {
    openAlertDialog: (state, action) => {
      const { title, contentText, size } = action.payload;
      state.open = true;
      state.title = title;
      state.contentText = contentText;
      state.size = size || state.size;
    },
    closeAlertDialog: (state) => {
      state.open = false;
    }
  }
});

export const { closeAlertDialog, openAlertDialog } = alertSlice.actions;

export default alertSlice.reducer;
