import PropTypes from 'prop-types';
import { SnackbarContent, useSnackbar } from 'notistack';

import { forwardRef, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';

// mui
import { Alert, AlertTitle, Box, Stack, Button, Typography } from '@mui/material';
import CalendarMonth from '@mui/icons-material/CalendarMonth';

import { useMsal } from '@azure/msal-react';

// third party
import { LoadingButton } from '@mui/lab';
import { syncOutlookCalendarEventsToDB } from 'utils/msGraphQueryHelper';
import handleError from 'utils/handle-error';
import callAzureFunction from 'utils/call-azure-function';

const RequestMsalAuthScopesSnackbar = forwardRef((props, ref) => {
  const { id } = props;
  const { closeSnackbar, enqueueSnackbar } = useSnackbar();
  const userData = useSelector((state) => state.user?.data);

  const [isLoading, setIsLoading] = useState(false);

  const { instance } = useMsal();

  const handleClose = useCallback(() => {
    closeSnackbar(id);
  }, [id, closeSnackbar]);

  const handleAcquireCalendarToken = async () => {
    setIsLoading(true);

    instance.setActiveAccount(null);

    try {
      const data = await instance.acquireTokenPopup({
        responseMode: 'query',
        scopes: [
          'openid',
          'User.Read',
          'Profile',
          'Calendars.Read',
          'Calendars.Read.Shared',
          'Calendars.ReadWrite',
          'Calendars.ReadWrite.Shared'
        ]
      });

      const { idTokenClaims, account, accessToken } = data;

      if (idTokenClaims?.email?.toLowerCase() !== userData.emailAddress.toLowerCase()) {
        enqueueSnackbar({
          variant: 'error',
          message:
            'Access grant failed. You signed in/ provided consent using a different account. Please try again and sign in using the logged in account.',
          anchorOrigin: { vertical: 'bottom', horizontal: 'right' }
        });

        return;
      }

      await callAzureFunction({ url: 'manager-outlook-calendar-event-save-token', method: 'post', data: { token: accessToken } });

      instance.setActiveAccount(account);

      await syncOutlookCalendarEventsToDB(accessToken);

      enqueueSnackbar({
        variant: 'success',
        message: 'Consent has been successfully provided!',
        anchorOrigin: { vertical: 'bottom', horizontal: 'right' }
      });
    } catch (error) {
      handleError(error);
      enqueueSnackbar({ variant: 'error', message: 'Permission grant failed' });
    } finally {
      setIsLoading(false);
      handleClose();
    }
  };

  return (
    <SnackbarContent ref={ref}>
      <Alert
        severity="success"
        variant="outlined"
        icon={<CalendarMonth color="primary" />}
        sx={{ bgcolor: 'background.paper', width: '100%', border: '0.5px solid' }}
        color="info"
      >
        <Box sx={{ width: 350 }}>
          <AlertTitle>Allow AtlasOne to read and write over Outlook calendar data</AlertTitle>
          <Typography variant="body2" color="white">
            Grant AtlasOne to access and sync your Outlook calendar events seamlessly into the app. Your availability based on the synced
            data will be visible to others when others book with you.
          </Typography>
          <Stack direction="row" justifyContent="flex-end" spacing={1} sx={{ pt: 2 }}>
            <LoadingButton variant="contained" color="primary" size="small" onClick={handleAcquireCalendarToken} loading={isLoading}>
              <span>Give Permissions</span>
            </LoadingButton>
            <Button variant="outlined" color="primary" size="small" onClick={handleClose}>
              Dismiss
            </Button>
          </Stack>
        </Box>
      </Alert>
    </SnackbarContent>
  );
});

RequestMsalAuthScopesSnackbar.propTypes = {
  id: PropTypes.string
};

export default RequestMsalAuthScopesSnackbar;
