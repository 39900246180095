import { useState, useCallback, useEffect } from 'react';

import PropTypes from 'prop-types';

import { Formik } from 'formik';
import * as Yup from 'yup';
// dayjs
import dayjs from 'dayjs';

// MUI
import {
  Autocomplete,
  Button,
  Card,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  FormHelperText,
  Grid,
  OutlinedInput,
  Skeleton,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useMediaQuery
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useTheme } from '@mui/material/styles';

// components
import AutocompleteWithInfiniteScroll from 'ui-component/autocompletes/AutocompleteWithInfiniteScroll';

// constants
import { affiliateBookingStatuses, bookingStatuses } from 'constants/bookings';
import userTypes from 'constants/userTypes';
import { bookingMessageTypes } from 'constants/bookingMessageTypes';

// utils
import { useGetEntityName } from 'utils/entities';
import callAzureFunction from 'utils/call-azure-function';
import handleError from 'utils/handle-error';
import { managerLocationOptions } from 'constants/managerOptions';
import { CustomStaticDatePicker } from 'ui-component/pickers/DateTimePickers';
import { dayIsUnavailable, dayIsValidWorkday, getAvailableTimeSlots } from 'utils/booking';
import { useSelector } from 'react-redux';
import { enqueueSnackbar } from 'notistack';

// ============================================ || ChangeBookingTypeDialog || ============================================ //

const ChangeBookingTypeDialog = ({ open, setOpen, booking, onClosed, onUpdated }) => {
  const {
    // id,
    // Manager: manager,
    // Respondent: respondent,
    // Location: currentLocation,
    Service: currentService,
    // Company: currentCompany,
    // anyManagerAtLocation,
    // respondentAvailabilityIsKnown,
    // date,
    // startHours,
    // startMinutes,
    // endHours,
    // endMinutes,
    // status,
    // caseId,
    // affiliateLocation,
    affiliateSelected
  } = booking;

  const user = useSelector((state) => state.user.data);

  const theme = useTheme();
  const matchesDownMd = useMediaQuery(theme.breakpoints.down('md'));

  const bookingEntity = useGetEntityName('booking');
  const managerEntityName = useGetEntityName('manager');

  const bookingStatusOptions = Object.values(bookingStatuses);
  const affiliateBookingStatusOptions = Object.values(affiliateBookingStatuses);

  // manager
  const [availableManagers, setAvailableManagers] = useState([]);
  const [loadingAvailableManagers, setLoadingAvailableManagers] = useState(false);
  const [selectedManagerOption, setSelectedManagerOption] = useState(managerLocationOptions.any.value);

  // location
  const [newLocation, setNewLocation] = useState(null);

  // date & time
  const [newDate, setNewDate] = useState(null);
  const [availableTimeSlots, setAvailableTimeSlots] = useState([]);
  const [loadingTimeSlots, setLoadingTimeSlots] = useState(false);
  const [newRespondentAvailabilityIsKnown, setNewRespondentAvailabilityIsKnown] = useState(true);

  const [isUpdating, setIsUpdating] = useState(false);

  const isPendingOrUnconfirmedBooking = !booking.date && !booking.newRespondentAvailabilityIsKnown && !affiliateSelected;

  const handleClose = () => {
    if (!isUpdating) {
      setOpen(false);
      setNewDate(null);
      setSelectedManagerOption(managerLocationOptions.any.value);
      setNewRespondentAvailabilityIsKnown(true);
      onClosed();
    }
  };

  const getAvailableManagers = useCallback(async () => {
    if (!newLocation) return;
    try {
      setLoadingAvailableManagers(true);

      const response = await callAzureFunction({
        url: 'managers-by-location',
        method: 'get',
        params: { locationId: newLocation.id }
      });
      const { rows } = response.data;

      setAvailableManagers(rows);
    } catch (error) {
      handleError(error);
    } finally {
      setLoadingAvailableManagers(false);
    }
  }, [newLocation]);

  useEffect(() => {
    getAvailableManagers();
  }, [getAvailableManagers]);

  const getTimeSlots = useCallback(async () => {
    if (!newDate || !newLocation) return;
    try {
      setLoadingTimeSlots(true);
      const timeSlots = await getAvailableTimeSlots({ date: newDate, service: currentService, location: newLocation });
      setAvailableTimeSlots(timeSlots);
    } catch (error) {
      handleError(error);
    } finally {
      setLoadingTimeSlots(false);
    }
  }, [currentService, newDate, newLocation]);

  useEffect(() => {
    getTimeSlots();
  }, [getTimeSlots]);

  return (
    <Dialog onClose={handleClose} maxWidth={affiliateSelected ? 'md' : 'sm'} fullWidth open={open}>
      <DialogTitle variant="h3">
        Change {bookingEntity} to {affiliateSelected ? 'Non-affiliate' : 'Affiliate'}
      </DialogTitle>
      <DialogContent>
        <Formik
          initialValues={{
            newStatus: !affiliateSelected ? affiliateBookingStatuses.Unconfirmed : null,
            newAffiliateLocation: '',
            isSpecifyAffiliateLoc: false,
            selectedAffiliateLoc: null,
            newManager: null,
            newLocation: null,
            newDate: null,
            newTimeSlot: null,
            submit: null
          }}
          validationSchema={() => {
            const fields = {};

            if (affiliateSelected) {
              fields.newStatus = Yup.object().required('Status is required');
              fields.newLocation = Yup.object().required('Location is required');
              if (newRespondentAvailabilityIsKnown) {
                fields.newDate = Yup.object().required('Date is required');
                fields.newTimeSlot = Yup.object().required('Time is required');
              }
              if (selectedManagerOption === managerLocationOptions.specific.value) {
                fields.newManager = Yup.object().required('Manager is required');
              }
            }
            return Yup.object(fields);
          }}
          onSubmit={async (values, { setErrors }) => {
            setIsUpdating(true);
            try {
              const { newAffiliateLocation, newLocation, newStatus, newManager, newTimeSlot, isSpecifyAffiliateLoc, selectedAffiliateLoc } =
                values;
              const data = { affiliateSelected: !affiliateSelected, updateUnsafeWithoutChecking: true, status: newStatus.value };

              if (isPendingOrUnconfirmedBooking) {
                data.respondentAvailabilityIsKnown = false;
                if (isSpecifyAffiliateLoc && newAffiliateLocation.trim()) {
                  data.affiliateLocation = newAffiliateLocation.trim();
                  data.isSelectFromSystemAffiliateLocations = false;
                  data.location = null;
                } else if (!isSpecifyAffiliateLoc && selectedAffiliateLoc) {
                  data.affiliateLocation = null;
                  data.isSelectFromSystemAffiliateLocations = true;
                  data.location = selectedAffiliateLoc;
                }
              } else if (affiliateSelected) {
                data.location = newLocation;
                data.isSelectFromSystemAffiliateLocations = false;
                data.managerOption = selectedManagerOption;
                data.manager = newManager;
                data.respondentAvailabilityIsKnown = newRespondentAvailabilityIsKnown;
                if (newRespondentAvailabilityIsKnown) {
                  data.date = dayjs(newDate).format('YYYY-MM-DD');
                  data.startHours = dayjs(newTimeSlot.start).hour();
                  data.startMinutes = dayjs(newTimeSlot.start).minute();
                  data.endHours = dayjs(newTimeSlot.end).hour();
                  data.endMinutes = dayjs(newTimeSlot.end).minute();
                } else {
                  data.status = null;
                  data.date = null;
                  data.startHours = null;
                  data.startMinutes = null;
                  data.endHours = null;
                  data.endMinutes = null;
                }
              } else if (!affiliateSelected) {
                data.respondentAvailabilityIsKnown = true;
                if (isSpecifyAffiliateLoc && newAffiliateLocation.trim()) {
                  data.affiliateLocation = newAffiliateLocation.trim();
                  data.isSelectFromSystemAffiliateLocations = false;
                  data.location = null;
                } else if (!isSpecifyAffiliateLoc && selectedAffiliateLoc) {
                  data.affiliateLocation = null;
                  data.isSelectFromSystemAffiliateLocations = true;
                  data.location = selectedAffiliateLoc;
                }
              }

              let updateUrl;

              if (user.type === userTypes.Admin) {
                updateUrl = `/bookings/${booking.id}`;
              }

              await callAzureFunction({ url: updateUrl, method: 'put', data });

              if (!affiliateSelected && data.status === affiliateBookingStatuses.Confirmed.value) {
                enqueueSnackbar({
                  variant: 'BOOKING_ACTION',
                  autoHideDuration: 20000,
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right'
                  },
                  variantProps: {
                    bookingId: booking.id,
                    respondentId: booking.respondentId,
                    messageType: bookingMessageTypes.Affiliate,
                    isMobileBooking: booking.isMobileBooking
                  }
                });
              }

              handleClose();
              onUpdated();
            } catch (error) {
              const errorMessage = handleError(error);
              setErrors({ submit: errorMessage });
            } finally {
              setIsUpdating(false);
            }
          }}
        >
          {({ values, setFieldValue, handleChange, handleBlur, handleSubmit, touched, errors }) => (
            <form noValidate onSubmit={handleSubmit}>
              <Stack gap={2}>
                {newRespondentAvailabilityIsKnown && (
                  <div>
                    {/* status */}
                    <Typography sx={{ mb: 1 }}>Status</Typography>
                    <Autocomplete
                      value={values.newStatus}
                      onChange={(event, newValue) => setFieldValue('newStatus', newValue)}
                      fullWidth
                      options={affiliateSelected ? bookingStatusOptions : affiliateBookingStatusOptions}
                      isOptionEqualToValue={(option, newVal) => option.value === newVal.value}
                      getOptionLabel={(option) => (option ? option.label : '')}
                      renderInput={(params) => (
                        <TextField
                          onBlur={handleBlur}
                          name="newStatus"
                          helperText={errors.newStatus}
                          error={errors.newStatus && touched.newStatus}
                          {...params}
                          placeholder="Select status"
                        />
                      )}
                    />
                  </div>
                )}
                <div>
                  {/* location */}
                  <Typography sx={{ mb: 1 }}>{affiliateSelected ? 'Location' : 'Affiliate Location (optional)'} </Typography>
                  {affiliateSelected ? (
                    <AutocompleteWithInfiniteScroll
                      // custom props
                      optionsUrl="locations/autocomplete"
                      optionsHighlight
                      // autocomplete props
                      id="select-location"
                      isOptionEqualToValue={(option, newVal) => option?.id === newVal?.id}
                      getOptionLabel={(option) => (option?.id ? `${option.name}` : '')}
                      onChange={(_, newValue) => {
                        setFieldValue('newLocation', newValue);
                        setNewLocation(newValue);
                      }}
                      value={values.newLocation}
                      renderInput={(params) => (
                        <TextField
                          onBlur={handleBlur}
                          name="newLocation"
                          helperText={errors.newLocation}
                          error={errors.newLocation}
                          {...params}
                          placeholder="Select location"
                        />
                      )}
                      fullWidth
                    />
                  ) : (
                    <>
                      {values.isSpecifyAffiliateLoc ? (
                        <OutlinedInput
                          fullWidth
                          name="newAffiliateLocation"
                          value={values.newAffiliateLocation}
                          onChange={handleChange}
                          placeholder="Enter affiliate location "
                        />
                      ) : (
                        <AutocompleteWithInfiniteScroll
                          // custom props
                          optionsUrl="locations/autocomplete?isAffiliate=true"
                          optionsHighlight
                          // autocomplete props
                          isOptionEqualToValue={(option, value) => option?.id === value?.id}
                          getOptionLabel={(option) => option?.name || ''}
                          onChange={(event, value) => {
                            setFieldValue('selectedAffiliateLoc', value);
                          }}
                          renderInput={(params) => <TextField {...params} placeholder="Select affiliate location" />}
                          fullWidth
                          style={{ maxWidth: '100%' }}
                        />
                      )}

                      <FormControlLabel
                        sx={{ '& > .MuiFormControlLabel-label': { opacity: 0.6 }, userSelect: 'none' }}
                        control={
                          <Checkbox
                            checked={values.isSpecifyAffiliateLoc}
                            onChange={(e) => setFieldValue('isSpecifyAffiliateLoc', e.target.checked)}
                          />
                        }
                        label="Specify location"
                      />
                    </>
                  )}
                </div>
                <div>
                  {values.newLocation && affiliateSelected ? (
                    <Stack gap={2}>
                      <div>
                        <Typography sx={{ mb: 1 }}>Manager</Typography>
                        <Grid container spacing={1}>
                          {Object.values(managerLocationOptions).map((managerOption) => (
                            <Grid item key={managerOption.value} xs={12} sm={6}>
                              <Card
                                variant="outlined"
                                sx={{
                                  p: 2,
                                  cursor: 'pointer',
                                  borderColor: (theme) => selectedManagerOption === managerOption.value && theme.palette.primary.main,
                                  borderWidth: selectedManagerOption === managerOption.value && 2
                                }}
                                onClick={() => setSelectedManagerOption(managerOption.value)}
                              >
                                <Typography color="primary">{managerOption.getLabel(managerEntityName)}</Typography>
                              </Card>
                            </Grid>
                          ))}
                        </Grid>
                      </div>
                      {selectedManagerOption === managerLocationOptions.specific.value && (
                        <>
                          <div>
                            {/* manager */}
                            {affiliateSelected && (
                              <Autocomplete
                                loading={loadingAvailableManagers}
                                value={values.newManager}
                                onChange={(event, newValue) => setFieldValue('newManager', newValue)}
                                fullWidth
                                options={availableManagers}
                                isOptionEqualToValue={(option, newVal) => option.id === newVal.id}
                                getOptionLabel={(option) => (option ? `${option.firstName} ${option.lastName}` : '')}
                                renderInput={(params) => (
                                  <TextField
                                    onBlur={handleBlur}
                                    name="newManager"
                                    helperText={errors.newManager}
                                    error={errors.newManager}
                                    {...params}
                                    label="Select manager"
                                  />
                                )}
                              />
                            )}
                          </div>
                        </>
                      )}
                    </Stack>
                  ) : (
                    <>
                      {affiliateSelected && (
                        <Typography variant="caption">
                          Note: please select location first, to select location&apos;s {managerEntityName} and service date & time
                        </Typography>
                      )}
                    </>
                  )}
                </div>
                {values.newLocation && affiliateSelected && (
                  <div>
                    <Divider sx={{ mb: 2 }} />

                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <Stack direction="row" alignItems="center" gap={1}>
                          <Typography variant="body2">Availability Known?</Typography>
                          <ToggleButtonGroup
                            value={newRespondentAvailabilityIsKnown}
                            size="small"
                            color="primary"
                            exclusive
                            onChange={(_, value) => setNewRespondentAvailabilityIsKnown(value)}
                          >
                            <ToggleButton value>Yes</ToggleButton>
                            <ToggleButton value={false}>No</ToggleButton>
                          </ToggleButtonGroup>
                        </Stack>
                      </Grid>
                      {/* date picker */}
                      {newRespondentAvailabilityIsKnown && (
                        <Grid item xs={12} sm={12} md={6}>
                          <CustomStaticDatePicker
                            value={newDate}
                            sx={{ mb: -6, mt: -2 }}
                            onChange={(value) => {
                              setNewDate(value);
                              setFieldValue('newDate', value);
                            }}
                            slotProps={{ actionBar: { actions: [] } }}
                            disablePast
                            shouldDisableDate={(value) => !dayIsValidWorkday(value, newLocation) || dayIsUnavailable(value, newLocation)}
                          />
                        </Grid>
                      )}
                      {/* time (slot) picker */}
                      {newRespondentAvailabilityIsKnown && (
                        <Grid item xs={12} sm={12} md={6}>
                          {loadingTimeSlots ? (
                            <Skeleton variant="rectangular" height={250} sx={{ mt: matchesDownMd ? 0 : 12 }} />
                          ) : (
                            <>
                              {availableTimeSlots.length > 0 ? (
                                <Grid container spacing={1} mt={matchesDownMd ? 0 : 12} sx={{ maxHeight: 250, overflow: 'auto' }}>
                                  {availableTimeSlots.map((slot) => (
                                    <Grid item key={slot.startText} xs={12} sm={6} md={4}>
                                      <Card
                                        variant="outlined"
                                        sx={{
                                          p: 2,
                                          cursor: 'pointer',
                                          textAlign: 'center',
                                          borderColor: values.newTimeSlot?.startText === slot.startText && theme.palette.primary.main,
                                          borderWidth: values.newTimeSlot?.startText === slot.startText && 2
                                        }}
                                        onClick={() => {
                                          setFieldValue('newTimeSlot', slot);
                                        }}
                                      >
                                        <Typography variant="h5">{slot.startText}</Typography>
                                      </Card>
                                    </Grid>
                                  ))}
                                </Grid>
                              ) : (
                                <Typography sx={{ mt: matchesDownMd ? 0 : 12 }}>No available time slots.</Typography>
                              )}
                            </>
                          )}
                        </Grid>
                      )}
                    </Grid>
                  </div>
                )}

                {affiliateSelected && values.newLocation && newRespondentAvailabilityIsKnown && (
                  <Stack gap={1} direction="row">
                    {errors.newDate && <FormHelperText error>{errors.newDate}.</FormHelperText>}
                    {errors.newTimeSlot && <FormHelperText error>{errors.newTimeSlot}.</FormHelperText>}
                  </Stack>
                )}

                {/* form error */}
                {errors.submit && <FormHelperText error>{errors.submit}</FormHelperText>}

                <LoadingButton loading={isUpdating} type="submit" variant="contained" sx={{ mt: 2 }}>
                  <span>Change to {affiliateSelected ? 'Non-affiliate' : 'Affiliate'}</span>
                </LoadingButton>
                <Button disabled={isUpdating} onClick={handleClose}>
                  Back
                </Button>
              </Stack>
            </form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

ChangeBookingTypeDialog.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  onClosed: PropTypes.func,
  booking: PropTypes.object,
  onUpdated: PropTypes.func
};

export default ChangeBookingTypeDialog;
