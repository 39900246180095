import { useState } from 'react';
import PropTypes from 'prop-types';

// material-ui
import { IconButton, Snackbar, Box } from '@mui/material';
// import clipboardCopy from 'clipboard-copy';
import clipboardCopy from 'copy-to-clipboard';

// assets
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const CopyToClipboardWithMUI = ({ textToCopy = '', sx, size, iconButtonSx, showSnackbar = true }) => {
  const [copied, setCopied] = useState(false);

  const handleCopyClick = async () => {
    try {
      await clipboardCopy(textToCopy);
      setCopied(true);
    } catch (error) {
      console.error('Error copying to clipboard:', error);
    }
  };

  const handleCloseSnackbar = () => {
    setCopied(false);
  };

  return (
    <Box sx={sx}>
      {textToCopy && (
        <IconButton onClick={handleCopyClick} size={size} sx={iconButtonSx}>
          <ContentCopyIcon fontSize="inherit" />
        </IconButton>
      )}
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={copied && showSnackbar}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        message="Copied to clipboard!"
      />
    </Box>
  );
};

CopyToClipboardWithMUI.propTypes = {
  textToCopy: PropTypes.string,
  sx: PropTypes.object,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  iconButtonSx: PropTypes.object,
  showSnackbar: PropTypes.bool
};

export default CopyToClipboardWithMUI;
