const AdministratorAccessRolePermissions = {
  VIEW_BOOKING_DASHBOARD: {
    // dashboards
    key: 'VIEW_BOOKING_DASHBOARD',
    name: 'View Booking Dashboard',
    group: 'Dashboards',
    description: `Allows you to view and monitor the Booking metrics`
  },
  VIEW_SURVEY_DASHBOARD: {
    key: 'VIEW_SURVEY_DASHBOARD',
    name: 'View Survey Dashboard',
    group: 'Dashboards',
    description: `Allows you to view and monitor the Survey metrics`
  },
  // users
  MANAGE_ADMINISTRATORS: {
    key: 'MANAGE_ADMINISTRATORS',
    name: 'Manage Administrators',
    group: 'Users',
    description: `Allows you to manage the Administrator users`
  },
  MANAGE_MANAGERS: {
    key: 'MANAGE_MANAGERS',
    name: 'Manage Managers',
    group: 'Users',
    description: `Allows you to manage the Manager users`
  },
  MANAGE_RESPONDENTS: {
    key: 'MANAGE_RESPONDENTS',
    name: 'Manage Respondents',
    group: 'Users',
    description: `Allows you to manage the Respondent users`
  },
  MANAGE_RESPONDENT_COMPANIES: {
    key: 'MANAGE_RESPONDENT_COMPANIES',
    name: "Manage Respondent's Company",
    group: 'Users',
    subPermission: true,
    description: `Allows you to assign a company to the Respondent`
  },
  MANAGE_ACCESS_ROLES: {
    key: 'MANAGE_ACCESS_ROLES',
    name: 'Manage Access Roles',
    group: 'Users',
    description: `Allows you to manage the Manager/Admin Access Roles`
  },
  MANAGE_RECIPIENTS: {
    key: 'MANAGE_RECIPIENTS',
    name: 'Manage Recipients',
    group: 'Users',
    description: `Allows you to manage the Recipients`
  },
  // bookings
  MANAGE_SERVICES_LOCATIONS_BOOKING_PAGES: {
    key: 'MANAGE_SERVICES_LOCATIONS_BOOKING_PAGES',
    name: 'Manage Services, Locations and Booking Pages',
    group: 'bookings',
    description: `Allows you to manage the Services, Locations and Booking Pages`
  },
  MANAGE_BOOKING_PAGE_COMPANIES: {
    key: 'MANAGE_BOOKING_PAGE_COMPANIES',
    name: "Manage Booking Page's Company",
    group: 'bookings',
    description: `Allows you to assign a company to the Booking Page`,
    subPermission: true
  },
  MANAGE_BOOKINGS: {
    key: 'MANAGE_BOOKINGS',
    name: 'Manage Bookings',
    group: 'bookings',
    description: `Allows you to manage the Bookings`
  },
  MANAGE_BOOKING_COMPANIES: {
    key: 'MANAGE_BOOKING_COMPANIES',
    name: "Manage Booking's Company",
    group: 'bookings',
    subPermission: true,
    description: `Allows you to assign a company to the Booking`
  },
  MANAGE_CONFIRMATION_TEXT: {
    key: 'MANAGE_CONFIRMATION_TEXT',
    name: 'Manage Confirmation Text',
    group: 'bookings',
    description: `Allows you to manage the Booking Confirmation Text`
  },
  // surveys
  MANAGE_SURVEY_BACKGROUNDS: {
    key: 'MANAGE_SURVEY_BACKGROUNDS',
    name: 'Manage Survey Backgrounds',
    group: 'surveys',
    description: `Allows you to manage the Survey Backgrounds`
  },
  MANAGE_SURVEY_TYPES: {
    key: 'MANAGE_SURVEY_TYPES',
    name: 'Manage Survey Types',
    group: 'surveys',
    description: `Allows you to manage the Survey Types`
  },
  VIEW_REPORTS: {
    key: 'VIEW_REPORTS',
    name: 'View Reports',
    group: 'surveys',
    description: `Allows you to view the Survey Reports`
  },
  // others
  MANAGE_APP_CONFIGURATIONS: {
    key: 'MANAGE_APP_CONFIGURATIONS',
    name: 'Manage App Configurations and Integrations',
    group: 'Others',
    description: `Allows you to manage the App Configurations and Integrations`
  },
  MANAGE_BILLING: {
    key: 'MANAGE_BILLING',
    name: 'Manage Billing',
    group: 'Others',
    description: `Allows you to manage the Billing`
  }
};

export default AdministratorAccessRolePermissions;
