import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  respondentId: null
};

const respondentSlice = createSlice({
  name: 'respondent',
  initialState,
  reducers: {
    setCurrentlyViewedRespondent: (state, action) => {
      state.respondentId = action.payload.data.respondentId;
    }
  }
});

export const { setCurrentlyViewedRespondent } = respondentSlice.actions;

export default respondentSlice.reducer;
