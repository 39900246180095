import { createSlice } from '@reduxjs/toolkit';
import { defaultAppConfig } from 'constants/appConfig';

export const initialState = {
  data: defaultAppConfig
};

const appConfigSlice = createSlice({
  name: 'appConfig',
  initialState,
  reducers: {
    setAppConfigData: (state, action) => {
      state.data = action.payload.data;
    },
    setBookingConfirmationText: (state, action) => {
      state.data.bookingConfirmationText = action.payload;
    },
    setAffiliateBookingConfirmationText: (state, action) => {
      state.data.bookingAffiliateConfirmationText = action.payload;
    },
    setCancelledBookingText: (state, action) => {
      state.data.cancelledBookingText = action.payload;
    },
    setDidNotAttendBookingText: (state, action) => {
      state.data.didNotAttendBookingText = action.payload;
    },
    setRescheduledBookingText: (state, action) => {
      state.data.rescheduledBookingText = action.payload;
    },
    setReminderBookingText: (state, action) => {
      state.data.reminderBookingText = action.payload;
    }
  }
});

// Action creators are generated for each case reducer function
export const {
  setAppConfigData,
  setBookingConfirmationText,
  setCancelledBookingText,
  setAffiliateBookingConfirmationText,
  setDidNotAttendBookingText,
  setRescheduledBookingText,
  setReminderBookingText
} = appConfigSlice.actions;

export default appConfigSlice.reducer;
