import { useState } from 'react';

// material-ui
// import { useTheme } from '@mui/material/styles';
import { Dialog, Typography, DialogContent, Button, Grid } from '@mui/material';

// assets
import { IconHelp } from '@tabler/icons-react';

// ==============================|| PROFILE MENU ||============================== //

const HelpSection = () => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      {/* <Chip
        sx={{
          height: '48px',
          marginRight: '10px',
          alignItems: 'center',
          borderRadius: '27px',
          transition: 'all .2s ease-in-out',
          borderColor: theme.palette.primary.light,
          backgroundColor: theme.palette.primary.light,
          '&[aria-controls="menu-list-grow"], &:hover': {
            borderColor: theme.palette.primary.dark,
            background: `${theme.palette.primary.dark}!important`,
            color: theme.palette.primary.light,
            '& svg': {
              stroke: 'white'
            }
          },
          '& .MuiChip-label': {
            lineHeight: 0
          }
        }}
        label={<IconHelp stroke={1.5} size="1.5rem" color={theme.palette.primary.main} />}
        variant="outlined"
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={() => handleOpen()}
        color="primary"
      /> */}

      <span style={{ cursor: 'pointer' }}>
        <IconHelp color="#1c1463" onClick={handleOpen} size={28} stroke={1.5} />
      </span>
      <Dialog open={open} fullWidth maxWidth="sm" onClose={handleClose}>
        <DialogContent sx={{ p: 4 }}>
          <Typography variant="h2" sx={{ mb: 3 }}>
            AtlasOne Support
          </Typography>
          <Typography sx={{ mb: 2 }}>If you require any technical support and/or practice advice then please contact:</Typography>
          <Typography sx={{ mb: 4 }}>
            Email &nbsp;
            <a href="mailto:info@atlasopen.com?subject=AtlasOne Support" target="_new">
              info@atlasopen.com
            </a>
          </Typography>
          <Grid item mt={2}>
            <Button variant="contained" onClick={() => handleClose()} sx={{ float: 'right' }}>
              Close
            </Button>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default HelpSection;
