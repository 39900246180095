/* eslint-disable import/prefer-default-export */
export const managerLocationOptions = {
  any: {
    value: 'anyManagerAtLocation',
    getLabel: (entityName) => `Any ${entityName} at location`
  },
  specific: {
    value: 'specificManager',
    getLabel: (entityName) => `Select specific ${entityName} at location`
  }
};
