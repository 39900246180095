// redux
import { createSlice } from '@reduxjs/toolkit';

// dayjs
import dayjs from 'dayjs';

// project constants
import { bookingStatuses } from 'constants/bookings';
import { calendarModes } from 'constants/calendar';

export const initialState = {
  calendarLocationFilter: [],
  calendarManagerFilter: [],
  calendarStatusFilter: Object.values(bookingStatuses).map((status) => status.value),
  calendarMinTimeFilter: '07:00', // 7 am is the earliest time shown on the calendar
  calendarMaxTimeFilter: '21:00', // 9pm is the latest time shown on the calendar,
  calendarStartDateFilter: dayjs().startOf('day').toISOString(),
  calendarEndDateFilter: dayjs().add(6, 'day').endOf('day').toISOString(),
  calendarHideWeekendsFilter: false,
  calendarModeFilter: calendarModes.ByLocation
};

const calendarFiltersSlice = createSlice({
  name: 'calendarFilters',
  initialState,
  reducers: {
    setCalendarLocationFilter: (state, action) => {
      state.calendarLocationFilter = action.payload;
    },
    setCalendarManagerFilter: (state, action) => {
      state.calendarManagerFilter = action.payload;
    },
    setCalendarStatusFilter: (state, action) => {
      state.calendarStatusFilter = action.payload;
    },
    setCalendarMinTimeFilter: (state, action) => {
      state.calendarMinTimeFilter = action.payload;
    },
    setCalendarMaxTimeFilter: (state, action) => {
      state.calendarMaxTimeFilter = action.payload;
    },
    setCalendarStartDateFilter: (state, action) => {
      state.calendarStartDateFilter = dayjs(action.payload).startOf('day').toISOString();
    },
    setCalendarEndDateFilter: (state, action) => {
      state.calendarEndDateFilter = dayjs(action.payload).endOf('day').toISOString();
    },
    setcalendarHideWeekendsFilter: (state, action) => {
      state.calendarHideWeekendsFilter = action.payload;
    },
    setCalendarModeFilter: (state, action) => {
      state.calendarModeFilter = action.payload;
    }
  }
});

export const {
  setCalendarLocationFilter,
  setCalendarManagerFilter,
  setCalendarStatusFilter,
  setCalendarMinTimeFilter,
  setCalendarMaxTimeFilter,
  setCalendarStartDateFilter,
  setCalendarEndDateFilter,
  setcalendarHideWeekendsFilter,
  setCalendarModeFilter
} = calendarFiltersSlice.actions;

export default calendarFiltersSlice.reducer;
