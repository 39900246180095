import { createSlice } from '@reduxjs/toolkit';

// project imports
import customizationConfig from '../../customizationConfig';

const initialState = {
  isOpen: [], // for active default menu
  fontFamily: customizationConfig.fontFamily,
  borderRadius: customizationConfig.borderRadius,
  opened: false
};

const customizationSlice = createSlice({
  name: 'customization',
  initialState,
  reducers: {
    menuOpen: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.isOpen = [action.payload.id];
    },
    setMenu: (state, action) => {
      state.opened = action.payload.opened;
    },
    setFontFamilyStyle: (state, action) => {
      state.fontFamily = action.payload.fontFamily;
    },
    setBorderRadiusStyle: (state, action) => {
      state.borderRadius = action.payload.borderRadius;
    }
  }
});

// Action creators are generated for each case reducer function
export const { menuOpen, setMenu, setFontFamilyStyle, setBorderRadiusStyle } = customizationSlice.actions;

export default customizationSlice.reducer;
