import adminPages from './adminPages';
import managerPages from './managerPages';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
  items: [],
  managerItems: [managerPages],
  adminItems: [...adminPages]
};

export default menuItems;
