// axios
import axios from 'axios';
// universal-cookie
import Cookies from 'universal-cookie';
//  jwt-decode
import { jwtDecode } from 'jwt-decode';

// dayjs
import dayjs from 'dayjs';

// project imports
import callAzureFunctionAuth from './call-azure-function-auth';
import clearReduxStore from './clear-redux-store';
import { store } from '../store';
import { setLoggedOut } from 'store/features/userSlice';
import { getCookieNameSuffix } from './cookies';

const functionConfig = {
  baseURL: import.meta.env.VITE_APP_AZ_FUNCTIONS_BASE_URI,
  withCredentials: true
};

/**
 * Refreshes the access token whenever it is about to expire.
 * If anything goes wrong, the user is logged out.
 * @param {*} accessToken The access token
 */
const refreshAccessToken = async (accessToken) => {
  try {
    if (accessToken) {
      // decode token
      const decoded = jwtDecode(accessToken);

      if (decoded?.exp) {
        const expDate = new Date(decoded.exp * 1000);

        // check if token is close to expiration
        if (dayjs(expDate).diff(dayjs(), 'minute') <= 1) {
          // refresh the token
          await callAzureFunctionAuth({
            url: `/auth-refresh-access`,
            method: 'get'
          });
        }
      }
    } else {
      throw new Error('No access token');
    }
  } catch (error) {
    // invalidate cookies
    callAzureFunctionAuth({
      url: `/auth-remove-access`,
      method: 'get'
    });
    // log out user
    store.dispatch(setLoggedOut());
    // clear redux store values
    clearReduxStore();
    // go to login page
    window.location.assign('/login');
  }
};

/**
 * Makes a request to a protected azure function that matches the specified url.
 * @param {object} requestConfig Axios request config. Only the `url` is required. For all available options see https://axios-http.com/docs/req_config.
 */
const callAzureFunction = async (requestConfig) => {
  const cookies = new Cookies(null, { path: '/' });

  // get cookie name suffix
  const cookieNameSuffix = getCookieNameSuffix();

  // get readable access token for functions
  const accessToken = cookies.get(`AF_ATHP${cookieNameSuffix}`);

  await refreshAccessToken(accessToken);

  const axiosResponse = await axios({
    ...requestConfig,
    ...functionConfig
  });

  return axiosResponse;
};

export default callAzureFunction;
