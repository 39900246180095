// react
import PropTypes from 'prop-types';

// mui
import { Typography, List } from '@mui/material';
import dayjs from 'dayjs';

// project imports
import { weekDays } from 'constants/bookings';
import { useGetEntityName } from 'utils/entities';

import { getDateWithCustomTime, getWorkdaysTextPerRRule, hasNoLunchTime } from 'utils/booking';

const BookingLocationCollisionDialog = ({ location, isMobileBookingMode }) => {
  const {
    workStartHours,
    workStartMinutes,
    workEndHours,
    workEndMinutes,
    lunchStartHours,
    lunchStartMinutes,
    lunchEndHours,
    lunchEndMinutes
  } = location;

  const workStartString = dayjs(getDateWithCustomTime(workStartHours, workStartMinutes, 0, 0)).format('hh:mmA');
  const workEndString = dayjs(getDateWithCustomTime(workEndHours, workEndMinutes, 0, 0)).format('hh:mmA');
  const lunchStartString = dayjs(getDateWithCustomTime(lunchStartHours, lunchStartMinutes, 0, 0)).format('hh:mmA');
  const lunchEndString = dayjs(getDateWithCustomTime(lunchEndHours, lunchEndMinutes, 0, 0)).format('hh:mmA');
  const bookingEntityName = useGetEntityName('booking');
  return (
    <List dense>
      <Typography gutterBottom variant="h5">
        {isMobileBookingMode
          ? `${bookingEntityName} is outside of the MSP's typical working hours at this location`
          : `${bookingEntityName} is outside of this location's typical working hours`}
      </Typography>
      <Typography mt={1}>
        {isMobileBookingMode ? (
          <>
            <b>MSP Servicing Location: </b>
            {location.address}
          </>
        ) : (
          <>
            <b>Location: </b>
            {location.name}
          </>
        )}
      </Typography>
      <Typography>
        <b>Work Days: </b>
        {/* eslint-disable-next-line no-nested-ternary */}
        {isMobileBookingMode
          ? getWorkdaysTextPerRRule(location.rrule)
          : location.workDays
            ? location.workDays
                .split(',')
                .sort()
                .map((day) => weekDays.find((item) => item.value === Number(day)).label)
                .join(', ')
            : ''}
      </Typography>
      <Typography>
        <b>Work Hours: </b>
        {`${workStartString} - ${workEndString}`}
      </Typography>
      {!hasNoLunchTime(location) && (
        <Typography>
          <b>Lunch Hours: </b>
          {`${lunchStartString} - ${lunchEndString}`}
        </Typography>
      )}
    </List>
  );
};

BookingLocationCollisionDialog.propTypes = {
  location: PropTypes.object,
  isMobileBookingMode: PropTypes.bool
};

export default BookingLocationCollisionDialog;
