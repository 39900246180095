import { useState, useRef, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';

import { useSelector } from 'react-redux';

// material-ui
import { useTheme, styled } from '@mui/material/styles';
import {
  Box,
  ClickAwayListener,
  Divider,
  Grid,
  Paper,
  Popper,
  Stack,
  Badge,
  FormControlLabel,
  Switch,
  IconButton,
  Typography,
  useMediaQuery,
  Chip,
  Skeleton
} from '@mui/material';

import PerfectScrollbar from 'react-perfect-scrollbar';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import Transitions from 'ui-component/extended/Transitions';
import NotificationListModal from './NotificationListModal';
import SelectNextNotifCountsToDisplay from './SelectNextNotifToDisplay';

import { IconBell } from '@tabler/icons-react';

// utils import
import handleError from 'utils/handle-error';
import callAzureFunction from 'utils/call-azure-function';

// project constants
import userTypes from 'constants/userTypes';

import { defaultNotifDisplayLimit } from 'constants/notifications';

const StyledSwitch = styled(Switch)(({ theme }) => ({
  padding: 8,
  '& .MuiSwitch-track': {
    borderRadius: 22 / 2,
    '&::before, &::after': {
      content: '""',
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      width: 16,
      height: 16
    },
    '&::before': {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main)
      )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
      left: 12
    },
    '&::after': {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main)
      )}" d="M19,13H5V11H19V13Z" /></svg>')`,
      right: 12
    }
  },
  '& .MuiSwitch-thumb': {
    boxShadow: 'none',
    width: 16,
    height: 16,
    margin: 2
  }
}));

// ==============================|| NOTIFICATION ||============================== //

const NotificationSection = ({ unreadNotifCount, getUnreadNotifCount }) => {
  const theme = useTheme();
  const matchesXs = useMediaQuery(theme.breakpoints.down('md'));
  const userData = useSelector((state) => state.user.data);

  const [open, setOpen] = useState(false);
  const [filterUnreadOnly, setFilterUnreadOnly] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [notifications, setNotifications] = useState([]);
  const [notifCount, setNotifCount] = useState(0);

  const [allReadOrUnreadNotifCount, setAllReadOrUnreadNotifCount] = useState(0);

  // keeps track on the number of batches called by the user upon calling getMoreNotifications()
  const [notifBatchCount, setNotifBatchCount] = useState(1);
  // default display limit size is the first limit option
  const [displaySize, setDisplaySize] = useState(defaultNotifDisplayLimit);

  const hiddenNotificationsCount = notifCount - notifications.length;

  const getNotifications = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await callAzureFunction({
        url: 'user-notifications',
        method: 'get',
        params: { filterUnreadOnly, limitSize: displaySize * notifBatchCount }
      });
      const { notificationCount, notificationList, allReadOrUnreadNotifCount } = response.data;

      setNotifications(notificationList);
      setNotifCount(notificationCount);
      setAllReadOrUnreadNotifCount(allReadOrUnreadNotifCount);
    } catch (error) {
      handleError(error);
    } finally {
      setIsLoading(false);
    }
  }, [filterUnreadOnly, displaySize, notifBatchCount]);

  const getMoreNotifications = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await callAzureFunction({
        url: 'user-notifications',
        method: 'get',
        params: { filterUnreadOnly, offset: displaySize * notifBatchCount, limitSize: displaySize }
      });
      const { notificationList } = response.data;

      setNotifications((prev) => [...prev, ...notificationList]);
      setNotifBatchCount(notifBatchCount + 1);
    } catch (error) {
      handleError(error);
    } finally {
      setIsLoading(false);
    }
  }, [filterUnreadOnly, displaySize, notifBatchCount]);

  /**
   * anchorRef is used on different componets and specifying one type leads to other components throwing an error
   * */
  const anchorRef = useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if ((anchorRef.current && anchorRef.current.contains(event.target)) || event.target.closest('.disable-click-away')) {
      return;
    }
    setOpen(false);
  };

  const handleFilterNotif = (e) => {
    setFilterUnreadOnly(e.target.checked);
  };

  const handleMarkAllAsRead = async () => {
    try {
      const urlNotifEndpoint = { [userTypes.Manager]: 'manager-notifications', [userTypes.Admin]: 'adms-notifications' };

      await callAzureFunction({
        url: `${urlNotifEndpoint[userData.type]}-mark-all-as-read`,
        method: 'put'
      });
      getUnreadNotifCount();
    } catch (error) {
      handleError(error);
    }
  };

  const handleSeeMoreNotif = () => {
    getMoreNotifications();
  };

  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }
    getNotifications();
    prevOpen.current = open;
  }, [open, getNotifications]);

  useEffect(() => {
    getNotifications();
  }, [filterUnreadOnly, getNotifications, unreadNotifCount, displaySize]);

  return (
    <>
      <Box ref={anchorRef} style={{ cursor: 'pointer' }} onClick={handleToggle}>
        <IconButton>
          <Badge badgeContent={unreadNotifCount} color="error" sx={{ mb: 0.5 }}>
            <IconBell color="#1c1463" size={28} stroke={1.5} />
          </Badge>
        </IconButton>
      </Box>
      <Popper
        placement={matchesXs ? 'bottom' : 'bottom-end'}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [matchesXs ? 5 : 0, 20]
              }
            }
          ]
        }}
      >
        {({ TransitionProps }) => (
          <Transitions position={matchesXs ? 'top' : 'top-right'} in={open} {...TransitionProps}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MainCard border={false} elevation={16} content={false} boxShadow shadow={theme.shadows[16]}>
                  <Grid container direction="column" spacing={1}>
                    <Grid item xs={12}>
                      <Grid container alignItems="center" justifyContent="space-between" sx={{ px: 2, pt: 2, pb: 1.5 }}>
                        <Grid item>
                          <Stack direction="row" spacing={2}>
                            <Typography variant="h4">Notifications</Typography>
                          </Stack>
                        </Grid>
                        {allReadOrUnreadNotifCount > 0 && (
                          <Grid item>
                            <FormControlLabel
                              control={<StyledSwitch checked={filterUnreadOnly} />}
                              labelPlacement="start"
                              onChange={handleFilterNotif}
                              checked={filterUnreadOnly}
                              label={<Typography variant="caption">Only Show Unread</Typography>}
                            />
                          </Grid>
                        )}
                      </Grid>
                      <Divider sx={{ borderBottomWidth: 1, borderColor: '#1C1463' }} variant="middle" />
                    </Grid>
                    <Grid item xs={12}>
                      {allReadOrUnreadNotifCount > 0 ? (
                        <>
                          <Stack direction="row" justifyContent="space-between" mx={2} mb={1}>
                            <Typography variant="subtitle2">LATEST</Typography>
                            {notifCount > 0 && (
                              <Typography
                                variant="body2"
                                sx={{
                                  '&:hover': {
                                    textDecoration: 'underline',
                                    cursor: 'pointer'
                                  }
                                }}
                                color="primary"
                                onClick={handleMarkAllAsRead}
                              >
                                Mark all as read
                              </Typography>
                            )}
                          </Stack>
                          <Divider sx={{ borderBottomWidth: 1, borderColor: '#1C1463' }} variant="middle" />

                          <PerfectScrollbar style={{ height: '100%', maxHeight: 'calc(100vh - 205px)', overflowX: 'hidden' }}>
                            <NotificationListModal
                              notifications={notifications}
                              getNotifications={getNotifications}
                              handleClose={() => setOpen(false)}
                              getUnreadNotifCount={getUnreadNotifCount}
                            />

                            {notifCount > notifications.length && (
                              <>
                                <Divider sx={{ borderBottomWidth: 2, borderColor: '#1C1463' }} />
                                <Stack direction="row" justifyContent="space-between" mx={2} mt={0.5} alignItems="center" pb={2}>
                                  {isLoading ? (
                                    <Skeleton variant="rectangular" height={15} width={200} sx={{ mt: 1 }} />
                                  ) : (
                                    <>
                                      <Typography variant="body2" color="primary" pt={0.5}>
                                        {hiddenNotificationsCount > 0 && `+${hiddenNotificationsCount}`} More{' '}
                                        {filterUnreadOnly && 'Unread '}
                                        Notification{hiddenNotificationsCount > 1 && 's'}
                                      </Typography>
                                      <Stack direction="row">
                                        <Chip
                                          color="primary"
                                          sx={{
                                            mr: 1,
                                            mt: 0.6
                                          }}
                                          onClick={() => handleSeeMoreNotif()}
                                          label="See Next Alerts"
                                          variant="outlined"
                                          clickable
                                          size="small"
                                        />
                                        <SelectNextNotifCountsToDisplay displaySize={displaySize} setDisplaySize={setDisplaySize} />
                                      </Stack>
                                    </>
                                  )}
                                </Stack>
                              </>
                            )}
                          </PerfectScrollbar>
                        </>
                      ) : (
                        <Grid container spacing={2}>
                          <Grid item xs={12} sx={{ height: 75, width: 600 }}>
                            <Typography variant="body2" sx={{ px: 2, py: 1 }}>
                              You have no notifications right now. Come back again later.
                            </Typography>
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>

                  <Divider />
                </MainCard>
              </ClickAwayListener>
            </Paper>
          </Transitions>
        )}
      </Popper>
    </>
  );
};

NotificationSection.propTypes = {
  notifications: PropTypes.array,
  setNotifications: PropTypes.func,
  getNotifications: PropTypes.func,
  unreadNotifCount: PropTypes.number,
  getUnreadNotifCount: PropTypes.func
};

export default NotificationSection;
