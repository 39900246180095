export default function themeForms(theme) {
  return {
    autoComplete: {
      '& > label': {
        top: '23px',
        '&[data-shrink="false"]': {
          top: '10px'
        }
      },
      '& > div ': {
        padding: '30px 6px 10px !important'
      },
      '& > div > input': {
        padding: '-10px !important'
      }
    },
    customInput: {
      marginTop: 1,
      marginBottom: 1,
      '& > label': {
        top: 23,
        left: 0,
        color: theme.grey500,
        '&[data-shrink="false"]': {
          top: 5
        }
      },
      '& > div > input': {
        padding: '30.5px 14px 11.5px !important'
      },
      '& legend': {
        display: 'none'
      },
      '& fieldset': {
        top: 0
      }
    }
  };
}
