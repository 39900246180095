// assets
import { IconClipboardText, IconUserCircle, IconCalendarEvent, IconBook2, IconLayersSubtract } from '@tabler/icons-react';
import ManagerAccessRolePermissions from 'constants/managerAccessRolePermissions';

// ==============================|| MANAGER PAGES MENU ITEMS ||============================== //

const managerPages = {
  id: 'managerPages',
  title: '',
  caption: '',
  type: 'group',
  children: [
    {
      id: 'respondents',
      entityId: 'respondents',
      title: 'Assigned Respondents',
      type: 'item',
      url: '/respondents',
      icon: IconUserCircle,
      breadcrumbs: false,
      minimumPermissions: []
    },
    {
      id: 'surveys',
      entityId: 'surveys',
      title: 'Surveys',
      type: 'item',
      url: '/surveys',
      icon: IconClipboardText,
      breadcrumbs: false,
      minimumPermissions: []
    },
    {
      id: 'booking-pages',
      title: 'Bookings Pages',
      type: 'item',
      url: '/manage-booking-pages',
      icon: IconLayersSubtract,
      breadcrumbs: false,
      minimumPermissions: []
    },
    {
      id: 'bookings',
      title: 'Bookings',
      type: 'item',
      url: '/manage-bookings',
      icon: IconBook2,
      breadcrumbs: false,
      minimumPermissions: []
    },
    {
      id: 'booking-calendar',
      title: 'Booking Calendar',
      type: 'item',
      url: '/manage-booking-calendar',
      icon: IconCalendarEvent,
      breadcrumbs: false,
      minimumPermissions: [ManagerAccessRolePermissions.BOOKING_CALENDAR_READ.key]
    }
  ]
};

export default managerPages;
