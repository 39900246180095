import { bookingMessageTypes } from 'constants/bookingMessageTypes';

const getSnackbarMessageType = ({
  locationChanged = false,
  serviceChanged = false,
  changeInDateTime = false,
  changeInStatus = false,
  status = ''
}) => {
  if (locationChanged) {
    return bookingMessageTypes.ChangeInLocation;
  }
  if (serviceChanged) {
    return bookingMessageTypes.ChangeInService;
  }

  if (changeInDateTime) {
    return bookingMessageTypes.Rescheduled;
  }
  if (changeInStatus) {
    const statusToBookingMessageTypesMap = {
      canceled: bookingMessageTypes.Canceled,
      dna: bookingMessageTypes.DidNotAttend,
      confirmed: bookingMessageTypes.Affiliate
    };
    return statusToBookingMessageTypesMap[status];
  }

  return bookingMessageTypes.MultipleChanges;
};

export default getSnackbarMessageType;
