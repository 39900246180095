const bookingMessageTypes = {
  Upcoming: {
    value: 'Upcoming',
    label: 'Scheduled',
    altLabel: 'Confirmation',
    getTitle: (bookingsEntityName) => `Scheduled ${bookingsEntityName}`,
    getSnackbarMessage: (bookingEntityName, bookingId) => `${bookingEntityName}  No. ${bookingId} has been successfully scheduled.`,
    isConfirmationTextOption: true
  },
  Affiliate: {
    value: 'Affiliate',
    label: 'Affiliate Scheduled',
    altLabel: 'Affiliate Confirmation',
    getTitle: (bookingsEntityName) => `Scheduled Affiliate ${bookingsEntityName}`,
    getSnackbarMessage: (bookingEntityName, bookingId) =>
      `Affiliate ${bookingEntityName}  No. ${bookingId} has been successfully confirmed.`,
    isConfirmationTextOption: true
  },
  Rescheduled: {
    value: 'Rescheduled',
    label: 'Rescheduled',
    altLabel: 'Change of Schedule',
    getTitle: (bookingsEntityName) => `Rescheduled ${bookingsEntityName}`,
    getSnackbarMessage: (bookingEntityName, bookingId) => `${bookingEntityName}  No. ${bookingId} has been successfully rescheduled.`,
    isConfirmationTextOption: true
  },
  Canceled: {
    value: 'Canceled',
    label: 'Canceled',
    altLabel: 'Cancellation',
    getTitle: (bookingsEntityName) => `Cancelled ${bookingsEntityName}`,
    getSnackbarMessage: (bookingEntityName, bookingId) => `${bookingEntityName}  No. ${bookingId} has been successfully canceled.`,
    isConfirmationTextOption: true
  },
  DidNotAttend: {
    value: 'DidNotAttend',
    label: 'Did Not Attend',
    altLabel: 'Did Not Attend',
    getTitle: (bookingsEntityName) => `Did-Not-Attend ${bookingsEntityName}`,
    getSnackbarMessage: (bookingEntityName, bookingId) =>
      `${bookingEntityName} No. ${bookingId} has been successfully updated as did-not-attend.`,
    isConfirmationTextOption: true
  },
  Reminder: {
    value: 'Reminder',
    label: 'Reminder',
    altLabel: 'Reminder',
    getTitle: (bookingsEntityName) => `Reminder for Scheduled ${bookingsEntityName}`,
    getSnackbarMessage: (bookingEntityName, bookingId) =>
      `${bookingEntityName} No. ${bookingId} has been successfully updated as did-not-attend.`,
    isConfirmationTextOption: true
  },
  ChangeInService: {
    value: 'ChangeInService',
    label: 'Change In Service',
    altLabel: 'Change In Service',
    getTitle: (bookingsEntityName) => `Change In ${bookingsEntityName}'s Service`,
    getSnackbarMessage: (bookingEntityName, bookingId) =>
      `The service for ${bookingEntityName}  No. ${bookingId} has been successfully changed.`,
    isConfirmationTextOption: false
  },
  ChangeInLocation: {
    value: 'ChangeInLocation',
    label: 'Change In Location',
    altLabel: 'Change In Location',
    getTitle: (bookingsEntityName) => `Change In ${bookingsEntityName}'s Location`,
    getSnackbarMessage: (bookingEntityName, bookingId) =>
      `The location, as well as the specified date and time and other details for ${bookingEntityName}  No. ${bookingId} have been successfully changed.`,
    isConfirmationTextOption: false
  },
  MultipleChanges: {
    value: 'MultipleChanges',
    label: 'Multiple Changes',
    altLabel: 'Various Changes',
    getTitle: (bookingsEntityName) => `Major Changes In ${bookingsEntityName} Terms`,
    getSnackbarMessage: (bookingEntityName, bookingId) =>
      `Various significant changes have been successfully applied to ${bookingEntityName}  No. ${bookingId} .`,
    isConfirmationTextOption: false
  }
};

const contactMethods = {
  Email: {
    value: 'email',
    label: 'Email'
  },
  SMS: {
    value: 'sms',
    label: 'SMS'
  }
};

export { bookingMessageTypes, contactMethods };
