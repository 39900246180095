import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { closeAlertDialog } from 'store/features/alertSlice';

const AlertDialog = () => {
  const { open, title, contentText, size } = useSelector((state) => state.alert);
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(closeAlertDialog());
  };

  return (
    <Dialog maxWidth={size} fullWidth open={open} onClose={handleClose}>
      <DialogTitle variant="h3">{title}</DialogTitle>
      {contentText && (
        <DialogContent>
          <Typography variant="subtitle1">{contentText}</Typography>
        </DialogContent>
      )}
      <DialogActions>
        <Button variant="contained" onClick={handleClose}>
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AlertDialog;
