// dayjs
import dayjs from 'dayjs';

/**
 * Checks if the two given intervals are overlapping each other. Adjacent intervals do not count as overlapping unless `inclusive` option is set to `true`.
 * Logic taken from https://github.com/date-fns/date-fns/blob/main/src/areIntervalsOverlapping/index.ts.
 * @param {Object} intervalLeft The first interval to compare.
 * @param {Date | dayjs.Dayjs | number | string} intervalLeft.start The start date of the first interval.
 * @param {Date | dayjs.Dayjs | number | string} intervalLeft.end The end date of the first interval.
 * @param {Object} intervalRight The second interval to compare.
 * @param {Date | dayjs.Dayjs | number | string} intervalRight.start The start date of the second interval.
 * @param {Date | dayjs.Dayjs | number | string} intervalRight.end The end date of the second interval.
 * @param {Object} options The object with options
 * @param {boolean} options.inclusive The object with options
 * @returns Whether the time intervals are overlapping
 */
const areIntervalsOverlapping = (intervalLeft, intervalRight, options = {}) => {
  const [leftStartTime, leftEndTime] = [dayjs(intervalLeft.start).toDate(), dayjs(intervalLeft.end).toDate()].sort((a, b) => a - b);
  const [rightStartTime, rightEndTime] = [dayjs(intervalRight.start).toDate(), dayjs(intervalRight.end).toDate()].sort((a, b) => a - b);

  if (options?.inclusive) return leftStartTime <= rightEndTime && rightStartTime <= leftEndTime;

  return leftStartTime < rightEndTime && rightStartTime < leftEndTime;
};

// eslint-disable-next-line import/prefer-default-export
export { areIntervalsOverlapping };
