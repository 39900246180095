import { Client } from '@microsoft/microsoft-graph-client';
import callAzureFunction from './call-azure-function';
import handleError from './handle-error';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

const checkIfEmailBelongsToLoggedInUser = (targetEmail, loggedInEmail) => targetEmail?.toLowerCase() === loggedInEmail?.toLowerCase();

const queryOutlookCalendarEvents = async (accessToken) => {
  let outlookCalendarEvents;

  const graphClient = Client.init({
    authProvider: (done) => {
      done(null, accessToken);
    }
  });
  const currentDate = new Date();
  const startDate = currentDate.toISOString(); // current date time
  const endDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 3, currentDate.getDate()).toISOString(); // 3 months from now

  const response = await graphClient.api(`/me/calendarview?startdatetime=${startDate}&enddatetime=${endDate}`).get();

  const calendarData = response?.value;

  if (calendarData.length > 0) {
    const eventsArray = calendarData.map((event) => {
      // isDraft
      const { iCalUId, showAs, isAllDay, start, end, isOnlineMeeting, isDraft, webLink, subject } = event;

      const { dateTime: startDateTimeRaw, startTimeZone } = start;
      const { dateTime: endDateTimeRaw, endTimeZone } = end;

      const startDateTime = dayjs(startDateTimeRaw).tz(startTimeZone).utc();
      const endDateTime = dayjs(endDateTimeRaw).tz(endTimeZone).utc();

      const date = dayjs(startDateTime).format('YYYY-MM-DD');

      const startHours = dayjs(startDateTime).hour();
      const startMinutes = dayjs(startDateTime).minute();
      const endHours = dayjs(endDateTime).hour();
      const endMinutes = dayjs(endDateTime).minute();

      const data = {
        eventId: iCalUId,
        timezone: 'UTC',
        date,
        startHours,
        startMinutes,
        endHours,
        endMinutes,
        showAs,
        isAllDay,
        isOnlineMeeting,
        isDraft,
        webLink,
        subject
      };

      return data;
    });

    outlookCalendarEvents = eventsArray;
  }

  return outlookCalendarEvents;
};

const syncOutlookCalendarEventsToDB = async (accessToken) => {
  const outlookCalendarEvents = await queryOutlookCalendarEvents(accessToken);

  const eventsArray = outlookCalendarEvents.filter((event) => !event.subject.includes('Mobile Booking'));

  if (outlookCalendarEvents?.length) {
    await callAzureFunction({
      url: 'manager-outlook-calendar-events-sync',
      method: 'post',
      data: { eventsArray }
    });
  }
};

const acquireTokenSilentlyAndSyncCalendarData = async (account, instance) => {
  let result;
  try {
    const data = await instance.acquireTokenSilent({
      account,
      scopes: [
        'openid',
        'User.Read',
        'Profile',
        'Calendars.Read',
        'Calendars.Read.Shared',
        'Calendars.ReadWrite',
        'Calendars.ReadWrite.Shared'
      ]
    });

    await syncOutlookCalendarEventsToDB(data?.accessToken);
    await callAzureFunction({ url: 'manager-outlook-calendar-event-save-token', method: 'post', data: { token: data?.accessToken } });
    // TODO - to put an indicator here that syncing of outlook calendar events has been successful
  } catch (error) {
    handleError(error);
  }
  return result;
};

// eslint-disable-next-line import/prefer-default-export
export {
  queryOutlookCalendarEvents,
  syncOutlookCalendarEventsToDB,
  checkIfEmailBelongsToLoggedInUser,
  acquireTokenSilentlyAndSyncCalendarData
};
