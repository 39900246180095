import UpcomingIcon from '@mui/icons-material/Upcoming';
import EventBusyIcon from '@mui/icons-material/EventBusy';
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
// import AutoModeIcon from '@mui/icons-material/AutoMode';
import DoNotDisturbOnTotalSilenceIcon from '@mui/icons-material/DoNotDisturbOnTotalSilence';
import EventRepeatIcon from '@mui/icons-material/EventRepeat';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import WorkIcon from '@mui/icons-material/Work';
import Groups3Icon from '@mui/icons-material/Groups3';
import RoomServiceIcon from '@mui/icons-material/RoomService';
import AddLocationAltIcon from '@mui/icons-material/AddLocationAlt';
import DynamicFormIcon from '@mui/icons-material/DynamicForm';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';

import { bookingStatuses } from './bookings';
import AdministratorAccessRolePermissions from './administratorAccessRolePermissions';
import ManagerAccessRolePermissions from './managerAccessRolePermissions';

// this can become dynamic in the future feature
const incrementalNotifDisplayOptions = [3, 5, 10, 20];
const defaultNotifDisplayLimit = incrementalNotifDisplayOptions[0];

const managerNotifTemplates = {
  BOOKING_CREATED_ASSIGNED_TO_MANAGER: {
    value: 'BOOKING_CREATED_ASSIGNED_TO_MANAGER',
    getTitle: 'Scheduled Booking',
    getIcon: <UpcomingIcon />,
    getColor: bookingStatuses.Upcoming.color
  },
  BOOKING_UPDATED_TO_CANCELED_STATUS: {
    value: 'BOOKING_UPDATED_TO_CANCELED_STATUS',
    getTitle: 'Canceled Booking',
    getIcon: <EventBusyIcon />,
    getColor: bookingStatuses.Canceled.color
  },
  BOOKING_UPDATED_TO_DNA_STATUS: {
    value: 'BOOKING_UPDATED_TO_DNA_STATUS',
    getTitle: 'Did Not Attend Booking',
    getIcon: <DoNotDisturbOnTotalSilenceIcon />,
    getColor: bookingStatuses.DidNotAttend.color
  },
  BOOKING_UPDATED_TO_COMPLETED_STATUS: {
    value: 'BOOKING_UPDATED_TO_COMPLETED_STATUS',
    getTitle: 'Completed Booking',
    getIcon: <DoneOutlineIcon />,
    getColor: bookingStatuses.Completed.color
  },
  BOOKING_UPDATED_TO_UPCOMING_STATUS: {
    value: 'BOOKING_UPDATED_TO_UPCOMING_STATUS',
    getTitle: 'Scheduled Booking',
    getIcon: <UpcomingIcon />,
    getColor: bookingStatuses.Upcoming.color
  },
  BOOKING_UPDATE_WITH_MULTIPLE_CHANGES: {
    value: 'BOOKING_UPDATE_WITH_MULTIPLE_CHANGES',
    getTitle: 'Multiple Changes in Booking',
    getIcon: <ChangeCircleIcon />,
    getColor: '#E8E4C9'
  },
  BOOKING_CHANGE_IN_DATE_AND_TIME: {
    value: 'BOOKING_CHANGE_IN_DATE_AND_TIME',
    getTitle: 'Rescheduled Booking',
    getIcon: <EventRepeatIcon />,
    getColor: '#7b93e7'
  },
  BOOKING_CHANGE_IN_SERVICE: {
    value: 'BOOKING_CHANGE_IN_SERVICE',
    getTitle: 'Change in Service',
    getIcon: <WorkIcon />,
    getColor: '#E8E4C9'
  },
  BOOKING_CHANGE_IN_LOCATION: {
    value: 'BOOKING_CHANGE_IN_LOCATION',
    getTitle: 'Change in Location',
    getIcon: <LocationCityIcon />,
    getColor: '#E8E4C9'
  },
  BOOKING_REASSIGNED_TO_ANOTHER_MANAGER: {
    value: 'BOOKING_REASSIGNED_TO_ANOTHER_MANAGER:',
    getTitle: 'Booking Reassignment to Another Manager',
    getIcon: <AssignmentIndIcon />,
    getColor: '#FFD580'
  },
  // -- reassignment of booking to any manager at location --
  BOOKING_REASSIGNED_TO_ANY_MANAGER_AT_LOCATION: {
    value: 'BOOKING_REASSIGNED_TO_ANY_MANAGER_AT_LOCATION',
    getTitle: 'Booking Reassignment to Any Manager At Location',
    getIcon: <Groups3Icon />,
    getColor: '#AFE1AF'
  }
};

// placeholder for future feature
const adminNotifTemplates = {
  BOOKING_PAGE_CREATED_BROADCAST: {
    value: 'BOOKING_PAGE_CREATED_BROADCAST',
    getTitle: 'New Booking Page',
    getIcon: <AutoStoriesIcon />,
    getColor: '#1AD19E'
  },
  BOOKING_PAGE_CHANGES_BROADCAST: {
    value: 'BOOKING_PAGE_CHANGES_BROADCAST',
    getTitle: 'Booking Page Updated',
    getIcon: <AutoStoriesIcon />,
    getColor: '#00C4C4'
  },
  LOCATION_CREATED_BROADCAST: {
    value: 'LOCATION_CREATED_BROADCAST',
    getTitle: 'New Location',
    getIcon: <AddLocationAltIcon />,
    getColor: '#1AD19E'
  },
  LOCATION_UPDATED_BROADCAST: {
    value: 'LOCATION_UPDATED_BROADCAST',
    getTitle: 'Location Details Update',
    getIcon: <AddLocationAltIcon />,
    getColor: '#00C4C4'
  },
  SERVICE_CREATED_BROADCAST: {
    value: 'SERVICE_CREATED_BROADCAST',
    getTitle: 'New Service',
    getIcon: <RoomServiceIcon />,
    getColor: '#1AD19E'
  },
  SERVICE_UPDATED_BROADCAST: {
    value: 'SERVICE_UPDATED_BROADCAST',
    getTitle: 'Service Details Update',
    getIcon: <RoomServiceIcon />,
    getColor: '#00C4C4'
  },
  SURVEY_TYPE_CREATED_BROADCAST: {
    value: 'SURVEY_TYPE_CREATED_BROADCAST',
    getTitle: 'New Survey Type Created',
    getIcon: <DynamicFormIcon />,
    getColor: '#1AD19E'
  },
  SURVEY_TYPE_VERSION_PUBLISHED_BROADCAST: {
    value: ' SURVEY_TYPE_VERSION_PUBLISHED_BROADCAST',
    getTitle: 'New Survey Type Version Published',
    getIcon: <DynamicFormIcon />,
    getColor: '#00C4C4'
  }
};

const notifEntityOrigins = {
  Booking: {
    value: 'Booking',
    getNavRoute: (entityRefId) => `/manage-bookings/${entityRefId}`,
    requiredPermissionAdmin: [AdministratorAccessRolePermissions.MANAGE_BOOKINGS.key],
    requiredPermissionManager: [ManagerAccessRolePermissions.BOOKING_READ.key]
  },
  BookingPage: {
    value: 'BookingPage',
    getNavRoute: (entityRefId) => `/manage-booking-pages/edit-booking-page/${entityRefId}`,
    requiredPermissionAdmin: [AdministratorAccessRolePermissions.MANAGE_SERVICES_LOCATIONS_BOOKING_PAGES.key],
    requiredPermissionManager: []
  },
  SurveyType: {
    value: 'SurveyType',
    getNavRoute: (entityRefId, latestVersionId) =>
      `/manage-survey-types/edit-survey-type/${entityRefId}/survey-type-version/${latestVersionId}`,
    requiredPermissionAdmin: [AdministratorAccessRolePermissions.MANAGE_SURVEY_TYPES.key],
    requiredPermissionManager: []
  },
  SurveyTypeVersion: {
    value: 'SurveyTypeVersion',
    getNavRoute: (entityRefId, surveyTypeId) => `/manage-survey-types/edit-survey-type/${surveyTypeId}/survey-type-version/${entityRefId}`,
    requiredPermissionAdmin: [AdministratorAccessRolePermissions.MANAGE_SURVEY_TYPES.key],
    requiredPermissionManager: []
  },
  Location: {
    value: 'Location',
    getNavRoute: (entityRefId) => `/manage-locations/edit-location/${entityRefId}`,
    requiredPermissionAdmin: [AdministratorAccessRolePermissions.MANAGE_SERVICES_LOCATIONS_BOOKING_PAGES.key],
    requiredPermissionManager: []
  },
  Service: {
    value: 'Service',
    getNavRoute: (entityRefId) => `/manage-services/edit-service/${entityRefId}`,
    requiredPermissionAdmin: [AdministratorAccessRolePermissions.MANAGE_SERVICES_LOCATIONS_BOOKING_PAGES.key],
    requiredPermissionManager: []
  }
};

export { defaultNotifDisplayLimit, incrementalNotifDisplayOptions, managerNotifTemplates, adminNotifTemplates, notifEntityOrigins };
