// material-ui
import { Grid, Typography, Box } from '@mui/material';

// Project Imports
import MainCard from 'ui-component/cards/MainCard';

// ================================|| Unauthorised ||================================ //

const Unauthorised = () => (
  <Grid container direction="column" justifyContent="center">
    <Grid item xs={12}>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item sx={{ m: { xs: 1, sm: 3 }, mb: 0 }}>
          <Grid container spacing={2} alignItems="center" justifyContent="center">
            <Grid item xs={12}>
              <MainCard>
                <Box sx={{ p: 5, textAlign: 'center' }}>
                  <Typography variant="h3" sx={{ textAlign: 'center' }}>
                    Oops, you do not have permission to view this page.
                  </Typography>
                  <Typography sx={{ mt: 1 }}>Please contact your administrator to gain access.</Typography>
                </Box>
              </MainCard>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  </Grid>
);

export default Unauthorised;
