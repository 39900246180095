import { multiplier } from './typography';

export default function componentStyleOverrides(theme) {
  const {
    primaryMain,
    primaryLight,
    secondaryMain,
    secondaryLight,
    secondary200,
    errorMain,
    grey700,
    grey400,
    grey300,
    grey200,
    grey50,
    primaryDark,
    primary200
  } = theme.colors;

  const getButtonStyle = ({ variant, color }) => {
    if (variant === 'containedTransition') {
      return {
        paddingTop: 5,
        paddingBottom: 5,
        background: color,
        border: `2px solid ${color}`,
        color: 'white',
        '&:hover': {
          color,
          border: `2px solid ${color}`
        },
        '&.Mui-disabled': {
          color: 'white',
          opacity: 0.6
        }
      };
    }

    if (variant === 'outlined') {
      return {
        border: `2px solid ${color}`,
        backgroundColor: 'white',
        '&:hover': {
          backgroundColor: color,
          color: 'white',
          border: `2px solid ${color}`
        }
      };
    }

    return {};
  };

  return {
    MuiLoadingButton: {
      defaultProps: {
        variant: 'outlined'
      },
      styleOverrides: {
        root: {
          '.MuiLoadingButton-loading': {
            border: `2px solid ${grey200}`
          }
        },
        containedTransition: {
          backgroundColor: `${grey50} !important`,
          color: grey200
        }
      }
    },
    MuiButton: {
      defaultProps: {
        variant: 'outlined'
      },
      styleOverrides: {
        root: {
          fontWeight: 500,
          boxShadow: 'none',
          fontSize: '0.875rem',
          '&:hover': {
            boxShadow: 'none'
          },
          '@media (min-width:600px)': {
            fontSize: `calc(0.875rem * ${multiplier})`
          }
        },
        contained: getButtonStyle({ variant: 'contained', color: primaryMain }),
        containedSecondary: getButtonStyle({ variant: 'contained', color: secondaryMain }),
        containedError: getButtonStyle({ variant: 'contained', color: errorMain }),
        containedTransition: getButtonStyle({ variant: 'containedTransition', color: primaryMain }),
        containedTransitionSecondary: getButtonStyle({ variant: 'containedTransition', color: secondaryMain }),
        containedTransitionError: getButtonStyle({ variant: 'containedTransition', color: errorMain }),
        outlined: getButtonStyle({ variant: 'outlined', color: primaryMain }),
        outlinedSecondary: getButtonStyle({ variant: 'outlined', color: secondaryMain }),
        outlinedError: getButtonStyle({ variant: 'outlined', color: errorMain }),
        textWhite: {
          '&.Mui-disabled': {
            border: 'none',
            color: grey300,
            opacity: 0.6
          }
        }
      }
    },
    MuiPaper: {
      defaultProps: {
        elevation: 0
      },
      styleOverrides: {
        root: {
          backgroundImage: 'none',
          '&.MuiSnackbarContent-root': {
            // snackbar
            backgroundColor: secondaryMain,
            color: '#fff',
            boxShadow: 'none',
            fontWeight: 500
          },
          '&.MuiAlert-root': {
            borderRadius: 4
          },
          '&.MuiAlert-filledSuccess': {
            backgroundColor: secondaryMain,
            color: '#fff',
            fontWeight: 500
          }
        },
        rounded: {
          borderRadius: `${theme?.customization?.borderRadius}px`
        }
      }
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {
          color: theme.colors?.textDark,
          padding: '24px'
        },
        title: {
          fontSize: '1.125rem'
        },
        '@media (min-width:600px)': {
          title: {
            fontSize: `calc(1.125rem * ${multiplier})`
          }
        }
      }
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: '24px'
        }
      }
    },
    MuiCardActions: {
      styleOverrides: {
        root: {
          padding: '24px'
        }
      }
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          color: theme.darkTextPrimary,
          paddingTop: '10px',
          paddingBottom: '10px',
          '&.Mui-selected': {
            color: 'inherit',
            backgroundColor: primaryLight,
            '&:hover': {
              backgroundColor: primaryLight
            }
          },
          '&:hover': {
            backgroundColor: '#eef2f6'
          }
        }
      }
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: theme.darkTextPrimary,
          minWidth: '36px'
        }
      }
    },
    MuiListItemText: {
      styleOverrides: {
        primary: {
          color: theme.textDark
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          color: theme.textDark,
          '&::placeholder': {
            color: theme.darkTextSecondary,
            fontSize: '0.875rem'
          }
        },
        '@media (min-width:600px)': {
          input: {
            fontSize: `calc(0.875rem * ${multiplier})`
          }
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          background: grey50,
          borderRadius: `${theme?.customization?.borderRadius}px`,
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: grey400
          },
          '&:hover $notchedOutline': {
            borderColor: primaryLight
          },
          '&.MuiInputBase-multiline': {
            padding: 1
          }
        },
        input: {
          fontWeight: 500,
          background: 'white',
          padding: '15.5px 14px',
          borderRadius: `${theme?.customization?.borderRadius}px`,
          '&.MuiInputBase-inputSizeSmall': {
            padding: '10px 14px',
            '&.MuiInputBase-inputAdornedStart': {
              paddingLeft: 0
            }
          }
        },
        inputAdornedStart: {
          paddingLeft: 4
        },
        notchedOutline: {
          borderRadius: `${theme?.customization?.borderRadius}px`
        }
      }
    },
    MuiSlider: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            color: theme.colors?.grey300
          }
        },
        mark: {
          backgroundColor: theme.paper,
          width: '4px'
        },
        valueLabel: {
          color: primaryLight
        }
      }
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: theme.divider,
          opacity: 1
        }
      }
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          color: primaryDark,
          background: primary200
        }
      }
    },
    MuiChip: {
      styleOverrides: {
        root: {
          '&.MuiChip-deletable .MuiChip-deleteIcon': {
            color: 'inherit'
          }
        }
      }
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          color: theme.paper,
          background: grey700
        }
      }
    },
    MuiDataGrid: {
      styleOverrides: {
        root: {
          borderBottom: `2px solid ${theme.colors?.secondaryPrimary}`,
          '& .MuiDataGrid-sortIcon': {
            color: 'white'
          },
          '& .MuiDataGrid-cell': {
            '&:focus': {
              outline: 'none'
            }
          },
          '& .MuiDataGrid-row': {
            '& .MuiDataGrid-withBorderColor': { border: 'none' },
            '&:hover': {
              backgroundColor: '#f9f9f9'
            },
            '&:nth-child(even)': {
              backgroundColor: secondaryLight
            },
            '&:nth-child(odd)': {
              backgroundColor: 'white'
            },
            '&:last-child': {
              borderBottom: `2px solid ${secondaryMain}`
            }
          },
          '& .MuiDataGrid-row.Mui-selected': {
            '&:nth-child(even)': {
              backgroundColor: secondaryLight
            },
            '&:hover': {
              backgroundColor: 'transparent',
              '&:nth-child(even)': {
                backgroundColor: secondaryLight
              },
              '&:nth-child(odd)': {
                backgroundColor: 'white'
              }
            }
          },
          '& .MuiDataGrid-columnHeader': {
            background: secondaryMain,
            color: 'white',
            userSelect: 'none',
            '&:focus': {
              outline: 'none'
            },
            borderRight: '1px solid white',
            '&:last-child': {
              border: 'none'
            }
          },
          '& .MuiDataGrid-columnHeaderTitleContainer': {
            '&:focus': {
              outline: 'none'
            }
          },
          '& .MuiDataGrid-iconSeparator': {
            display: 'none'
          }
        }
      }
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            backgroundColor: '#FFF !important'
          }
        }
      }
    },
    MuiMenu: {
      styleOverrides: {
        root: {
          '& .MuiMenu-paper': {
            boxShadow: '0 0 3px 0 rgba(0,0,0,0.6)',
            borderRadius: 4
          }
        }
      }
    },
    MuiStepper: {
      styleOverrides: {
        root: {
          '& .MuiStepIcon-root': {
            color: secondary200
          },
          '& .MuiStepIcon-root.Mui-active': {
            color: secondaryMain
          },
          '& .MuiStepIcon-root.Mui-completed': {
            color: secondaryMain
          }
        }
      }
    }
  };
}
