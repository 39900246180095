// react
import PropTypes from 'prop-types';

// redux
import { useSelector } from 'react-redux';

// project imports
import Unauthorised from '../../views/unauthorised/Unauthorised';

const PrivateRoute = ({ children, role }) => {
  const userData = useSelector((state) => state.user.data);

  const enabledPermissions = userData.AccessRoles.reduce((prev, curr) => {
    const permissions = JSON.parse(curr.permissions);
    return [...new Set([...prev].concat(permissions))];
  }, []);

  const userHasRole = enabledPermissions.includes(role);

  if (!userHasRole) {
    return <Unauthorised />;
  }

  return children;
};

PrivateRoute.propTypes = {
  children: PropTypes.node,
  role: PropTypes.string
};

export default PrivateRoute;
