import { useSelector } from 'react-redux';
import { getImageUrl } from 'utils/entities';

// ==============================|| LOGO SVG ||============================== //

const SidebarLogo = () => {
  const appConfig = useSelector((state) => state.appConfig?.data);
  const imgUrl = getImageUrl('sidebarLogo', appConfig);
  return <img src={imgUrl} alt="Logo" width="180" height="48" style={{ objectFit: 'contain' }} />;
};

export default SidebarLogo;
