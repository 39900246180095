import { useRef, useEffect, useState } from 'react';
import { Select, MenuItem, Tooltip } from '@mui/material';
import { incrementalNotifDisplayOptions } from 'constants/notifications';
import PropTypes from 'prop-types';

const SelectNextNotifCountsToDisplay = ({ displaySize, setDisplaySize }) => {
  const selectRef = useRef();
  const menuRef = useRef();
  const [open, setOpen] = useState(false);

  const handleChange = (e) => {
    setDisplaySize(e.target.value);
    setOpen(false);
  };

  useEffect(() => {
    const onMouseUp = (e) => {
      if (selectRef.current.contains(e.target)) {
        setOpen(true);
        return;
      }
      if ((!displaySize && menuRef.current?.contains(e.target)) || !menuRef.current?.contains(e.target)) {
        setOpen(false);
      }
    };
    window.addEventListener('mouseup', onMouseUp);
    return () => {
      window.removeEventListener('mouseup', onMouseUp);
    };
  }, [displaySize]);

  return (
    <Tooltip title={`See the next batch of notifications by ${displaySize}`} placement="top-start" PopperProps={{ sx: { zIndex: 1200 } }}>
      <Select
        MenuProps={{
          className: 'disable-click-away',
          MenuListProps: { ref: menuRef }
        }}
        SelectDisplayProps={{ sx: { height: 10, background: 'none !important', bgcolor: 'none' } }}
        sx={{ height: 23, background: 'none !important', mt: 0.6 }}
        open={open}
        ref={selectRef}
        value={displaySize}
        onChange={handleChange}
      >
        {incrementalNotifDisplayOptions.map((each, index) => (
          <MenuItem key={index} className="disable-click-away" value={each} sx={{ height: 20 }}>
            {each}
          </MenuItem>
        ))}
      </Select>
    </Tooltip>
  );
};

SelectNextNotifCountsToDisplay.propTypes = {
  displaySize: PropTypes.number,
  setDisplaySize: PropTypes.func
};

export default SelectNextNotifCountsToDisplay;
