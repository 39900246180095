// react
import { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';

// redux
import { useSelector } from 'react-redux';

// mui
import {
  Dialog,
  Typography,
  DialogContent,
  Button,
  Grid,
  DialogActions,
  Table,
  TableBody,
  Divider,
  TableContainer,
  TableRow,
  TableCell,
  Paper,
  FormControlLabel,
  Checkbox,
  Stack,
  Alert,
  Tooltip,
  LinearProgress
} from '@mui/material';

// project imports
import handleError from 'utils/handle-error';
import callAzureFunction from 'utils/call-azure-function';
import { getEntityName } from 'utils/entities';

import validContactMethods from 'constants/validContactMethods';
import { bookingMessageTypes } from 'constants/bookingMessageTypes';
import { bookingStatuses } from 'constants/bookings';

const SendBookingDetailsDialog = ({ open, setOpen, dialogProps, handleCloseSnackbar, snackbarId, displaySnackbarAtParent }) => {
  const appConfig = useSelector((state) => state.appConfig?.data);
  const bookingEntityName = getEntityName('booking', appConfig);
  const respondentEntityName = getEntityName('respondent', appConfig);

  const { respondentId, bookingId, messageType } = dialogProps;

  const [isSending, setIsSending] = useState(false);
  const [respondentDetails, setRespondentDetails] = useState();

  // contact methods selected states
  const [emailAddressChecked, setEmailAddressChecked] = useState(false);
  const [contactEmailChecked, setContactEmailChecked] = useState(false);
  const [contactMobilePhoneChecked, setContactMobilePhoneChecked] = useState(false);

  const getRespondentDetails = async (id) => {
    try {
      const response = await callAzureFunction({ url: `respondents/${id}`, method: 'get' });
      setRespondentDetails(response?.data);
    } catch (error) {
      handleError(error);
    }
  };

  useEffect(() => {
    getRespondentDetails(respondentId);
  }, [respondentId, open, messageType]);

  const handleSend = useCallback(async () => {
    try {
      setIsSending(true);
      const {
        bookingConfirmationText,
        cancelledBookingText,
        didNotAttendBookingText,
        bookingAffiliateConfirmationText,
        rescheduledBookingText
      } = appConfig;
      const typeToConfigMap = {
        [bookingMessageTypes.Upcoming.value]: bookingConfirmationText,
        [bookingMessageTypes.Rescheduled.value]: rescheduledBookingText,
        [bookingMessageTypes.Canceled.value]: cancelledBookingText,
        [bookingMessageTypes.DidNotAttend.value]: didNotAttendBookingText,
        [bookingMessageTypes.Affiliate.value]: bookingAffiliateConfirmationText
      };

      const getMessageComponents = (type) => {
        const config = typeToConfigMap[type] || bookingConfirmationText;

        return {
          emailSubject: config.emailSubject,
          emailBody: config.emailBody,
          smsBody: config.smsBody
        };
      };

      const { emailSubject, emailBody, smsBody } = getMessageComponents(messageType.value);

      // send email confirmation
      await callAzureFunction({
        url: `/bookings/${bookingId}/send-confirmation`,
        method: 'post',
        data: {
          contactMethods: appConfig?.generalSettings.sendBySmsEnabled
            ? [validContactMethods.Email, validContactMethods.SMS]
            : [validContactMethods.Email],
          emailSubject,
          emailBody,
          smsBody,
          emailConfirmationLogo: appConfig?.images.emailConfirmationLogo,
          selectedContactMethods: {
            emailAddress: emailAddressChecked,
            contactEmail: contactEmailChecked,
            contactMobilePhone: contactMobilePhoneChecked
          },
          forceSend: true
        }
      });
      handleCloseSnackbar(snackbarId);
      displaySnackbarAtParent(`${getEntityName('booking', appConfig)} details have been successfully sent.`, {
        variant: 'success'
      });
    } catch (error) {
      const errMsg = handleError(error);
      displaySnackbarAtParent(`Failed to send. ${errMsg}`, { variant: 'error' });
    } finally {
      setIsSending(false);
      setOpen(false);
    }
  }, [
    appConfig,
    bookingId,
    messageType,
    emailAddressChecked,
    contactEmailChecked,
    contactMobilePhoneChecked,
    handleCloseSnackbar,
    snackbarId,
    displaySnackbarAtParent,
    setOpen
  ]);

  const handleClose = () => {
    setOpen(false);
    setEmailAddressChecked(false);
    setContactEmailChecked(false);
    setContactMobilePhoneChecked(false);
  };

  const isButtonDisabled = !(emailAddressChecked || contactEmailChecked || contactMobilePhoneChecked);
  const mainHeaderTitle =
    messageType.value.toLowerCase() === bookingStatuses.Upcoming.value
      ? `Send ${bookingEntityName} Details`
      : `Send Updated ${bookingEntityName} Details`;

  const subHeaderTitle =
    messageType.value.toLowerCase() === bookingStatuses.Upcoming.value
      ? `Booking No. ${bookingId} has been successfully scheduled`
      : `${messageType.getSnackbarMessage(bookingEntityName, bookingId)}`;

  return (
    <>
      <Dialog open={open} onClose={handleClose} sx={{ zIndex: 500 }} maxWidth="xs">
        <LinearProgress variant={isSending ? 'indeterminate' : 'buffer'} valueBuffer={100} value={100} />
        <DialogContent>
          <Grid container alignItems="center" mb={3}>
            <Grid item spacing={2}>
              <Typography variant="h3" mb={2}>
                {mainHeaderTitle}
              </Typography>
              <Typography variant="h5" mb={1.5}>
                {subHeaderTitle}
              </Typography>
              <Typography variant="body1">
                Would you like to send the pertinent details to the {respondentEntityName.toLowerCase()}?
              </Typography>
            </Grid>
          </Grid>
          <TableContainer component={Paper} sx={{ pl: 2, pb: 1 }}>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell style={{ padding: 0, border: 'none' }}>
                    <Typography sx={{ mb: 1 }} variant="subtitle1">{`${respondentEntityName} Contact Details`}</Typography>
                    <Stack gap={1}>
                      <Typography>
                        Name: {respondentDetails?.firstName} {respondentDetails?.lastName}
                      </Typography>
                      {respondentDetails?.emailAddress && <Typography>Email: {respondentDetails?.emailAddress}</Typography>}
                      {respondentDetails?.contactEmail && <Typography>Contact Email: {respondentDetails?.contactEmail}</Typography>}
                      {respondentDetails?.contactMobilePhone && (
                        <Typography>Contact Mobile Phone: {respondentDetails?.contactMobilePhone}</Typography>
                      )}
                    </Stack>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <Divider sx={{ pb: 1 }} />
          <Typography variant="h4" paddingTop={2}>
            Send to:
          </Typography>
          <Stack>
            <FormControlLabel
              control={
                <Checkbox
                  checked={emailAddressChecked}
                  onChange={() => setEmailAddressChecked(!emailAddressChecked)}
                  disabled={!respondentDetails?.emailAddress}
                />
              }
              label="Email Address"
            />

            <Tooltip
              title={
                // eslint-disable-next-line no-nested-ternary
                respondentDetails?.contactEmail === respondentDetails?.emailAddress
                  ? "The recipient's email address is the same with contact email."
                  : !respondentDetails?.contactEmail
                    ? 'The recipient has no contact email.'
                    : "Send to recipient's contact email"
              }
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={contactEmailChecked}
                    onChange={() => setContactEmailChecked(!contactEmailChecked)}
                    // disable this option if the contact email is the same with email address
                    disabled={!respondentDetails?.contactEmail || respondentDetails?.contactEmail === respondentDetails.emailAddress}
                  />
                }
                hover
                label="Contact Email"
              />
            </Tooltip>

            <FormControlLabel
              control={
                <Checkbox
                  checked={contactMobilePhoneChecked}
                  onChange={() => setContactMobilePhoneChecked(!contactMobilePhoneChecked)}
                  disabled={!respondentDetails?.contactMobilePhone || !appConfig?.generalSettings.sendBySmsEnabled}
                />
              }
              label={appConfig?.generalSettings.sendBySmsEnabled ? 'Mobile Phone' : 'Mobile Phone (Not enabled)'}
              hover
            />
          </Stack>
          {!emailAddressChecked && !contactEmailChecked && !contactMobilePhoneChecked && (
            <Alert severity="error" sx={{ mt: 2 }}>
              Please select at least one Contact Method.
            </Alert>
          )}
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleSend} disabled={isSending || isButtonDisabled} sx={{ minWidth: '100px' }}>
            YES
          </Button>
          <Button onClick={handleClose} disabled={isSending} sx={{ minWidth: '100px' }}>
            NO
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

SendBookingDetailsDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  dialogProps: PropTypes.object,
  handleCloseSnackbar: PropTypes.func.isRequired,
  snackbarId: PropTypes.string.isRequired,
  displaySnackbarAtParent: PropTypes.func.isRequired
};

export default SendBookingDetailsDialog;
