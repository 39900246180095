const handlePreventKeyDown = (event) => {
  // Disallow non-numeric characters, decimal points, and negative signs
  if (event.key === '-' || (event.key.length === 1 && typeof event.key === 'string' && !/[0-9]/.test(event.key))) {
    event.preventDefault();
  }
};

const capitalizeFirstLetter = (string) => {
  if (!string || typeof string !== 'string') {
    return '';
  }

  return string.charAt(0).toUpperCase() + string.slice(1);
};

// eslint-disable-next-line import/prefer-default-export
export { handlePreventKeyDown, capitalizeFirstLetter };
