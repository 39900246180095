/* eslint-disable no-restricted-syntax */
import userTypes from 'constants/userTypes';
import ManagerAccessRolePermissions from 'constants/managerAccessRolePermissions';

const getUserPermissions = (user = {}) => {
  const accessRoles = user.AccessRoles || [];
  const permissions = new Set();

  for (const accessRole of accessRoles) {
    for (const permission of JSON.parse(accessRole.permissions)) {
      permissions.add(permission);
    }
  }
  return Array.from(permissions);
};

const userHasPermission = ({ userData = {}, validPermissionsAdmin = [], validPermissionsManager = [] }) => {
  let hasPermission = true;
  let validPermissions = [];

  if (userData.type === userTypes.Admin) {
    validPermissions = validPermissionsAdmin;
  } else if (userData.type === userTypes.Manager) {
    validPermissions = validPermissionsManager;
  }

  if (validPermissions.length > 0) {
    hasPermission = false;
    // get user permissions
    const userPermissions = getUserPermissions(userData);

    for (const permission of validPermissions) {
      if (userPermissions.includes(permission)) {
        hasPermission = true;
        break;
      }
    }
  }

  return hasPermission;
};

const userHasSpecificPermission = (userData = {}, specificPermission) => {
  // eslint-disable-next-line no-prototype-builtins
  if (ManagerAccessRolePermissions.hasOwnProperty(specificPermission) && userData.type === userTypes.Admin) {
    // if the specific permission belongs to manager access role and
    // and the user type is admin
    // then grant implicit permission
    return true;
  }

  const userPermissions = getUserPermissions(userData);

  if (userPermissions.length > 0) {
    return userPermissions.includes(specificPermission);
  }

  return false;
};

export { userHasPermission, userHasSpecificPermission };
