import { store } from '../store';

/**
 * Clears all redux items from `localStorage`.
 * These items are stored in `persist:root` as defined in its `persistConfig`.
 */

const clearReduxStore = () => {
  store.dispatch({ type: 'RESET_APP' });
};

export default clearReduxStore;
