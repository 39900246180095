import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  message: '',
  open: false,
  duration: 5000,
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'right'
  },
  otherData: {}
};

const snackbarSlice = createSlice({
  name: 'snackbar',
  initialState,
  reducers: {
    openSnackbar: (state, action) => {
      const { message, open, anchorOrigin, duration, otherData } = action.payload;

      state.message = message || initialState.message;
      state.open = open || initialState.open;
      state.duration = duration || initialState.duration;
      state.anchorOrigin = anchorOrigin || initialState.anchorOrigin;
      state.otherData = otherData || initialState.otherData;
    },
    closeSnackbar: (state) => {
      state.open = false;
    },
    clearOtherData: (state) => {
      state.otherData = {};
    }
  }
});

export const { openSnackbar, closeSnackbar, clearOtherData } = snackbarSlice.actions;

export default snackbarSlice.reducer;
