import PropTypes from 'prop-types';
import { Fragment } from 'react';
import { useNavigate } from 'react-router-dom';

// material-ui
import { useTheme, styled } from '@mui/material/styles';
import { Avatar, Divider, Grid, List, ListItem, ListItemAvatar, Box, ListItemText, Typography, IconButton, Tooltip } from '@mui/material';

import CircleIcon from '@mui/icons-material/Circle';
import TonalityIcon from '@mui/icons-material/Tonality';

// project utils and constant
import getDuration from 'utils/get-duration';
import callAzureFunction from 'utils/call-azure-function';
import handleError from 'utils/handle-error';
import { userHasPermission } from 'utils/permissions';

import { managerNotifTemplates, adminNotifTemplates, notifEntityOrigins } from 'constants/notifications';
import { useSelector } from 'react-redux';
import userTypes from 'constants/userTypes';

// styles
const ListItemWrapper = styled('div')(({ theme }) => ({
  cursor: 'pointer',
  padding: 16,
  '&:hover': {
    background: theme.palette.primary.light
  },
  '& .MuiListItem-root': {
    padding: 0
  }
}));

// ==============================|| NOTIFICATION LIST ITEM ||============================== //

const NotificationListModal = ({ notifications, getUnreadNotifCount, handleClose }) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const userData = useSelector((state) => state.user.data);
  const userNotifTemplates = userData.type === userTypes.Manager ? managerNotifTemplates : adminNotifTemplates;
  const urlNotifEndpoint = { [userTypes.Manager]: 'manager-notifications', [userTypes.Admin]: 'adms-notifications' };

  const handleMarkAsRead = async (id) => {
    try {
      await callAzureFunction({ url: `${urlNotifEndpoint[userData.type]}/${id}`, method: 'put', data: { isRead: true } });
      getUnreadNotifCount();
    } catch (error) {
      handleError(error);
    }
  };

  const handleMarkAsUnread = async (id) => {
    try {
      await callAzureFunction({ url: `${urlNotifEndpoint[userData.type]}/${id}`, method: 'put', data: { isRead: false } });
      getUnreadNotifCount();
    } catch (error) {
      handleError(error);
    }
  };

  const getOptionalParamForNavigation = async (entityOrigin, entityRefId) => {
    let optionalParamId = null;

    if (entityOrigin === notifEntityOrigins.SurveyTypeVersion.value) {
      const response = await callAzureFunction({ url: `survey-type-versions/${entityRefId}`, method: 'get' });

      const result = response?.data;
      const { surveyTypeId } = result;

      optionalParamId = surveyTypeId;
    }

    if (entityOrigin === notifEntityOrigins.SurveyType.value) {
      const response = await callAzureFunction({ url: `survey-types/${entityRefId}`, method: 'get' });

      const result = response?.data;
      const { latestVersionId } = result;

      optionalParamId = latestVersionId;
    }

    return optionalParamId;
  };

  const handleNavigate = async (notif) => {
    const { id, entityRefId, entityOrigin, isRead } = notif;
    const optionalParamId = await getOptionalParamForNavigation(entityOrigin, entityRefId);

    const targetRoute = notifEntityOrigins[entityOrigin].getNavRoute(entityRefId, optionalParamId);
    const isPermittedToNavigate = userHasPermission({
      userData,
      validPermissionsAdmin: notifEntityOrigins[entityOrigin].requiredPermissionAdmin,
      validPermissionsManager: notifEntityOrigins[entityOrigin].requiredPermissionManager
    });

    try {
      if (isRead === false) {
        await handleMarkAsRead(id);
      }
    } catch (error) {
      handleError(error);
    } finally {
      if (isPermittedToNavigate) {
        navigate(targetRoute);
      }
      handleClose();
    }
  };

  return (
    <>
      {notifications.length > 0 ? (
        <>
          <List
            sx={{
              width: '100%',
              maxWidth: 600,
              minWidth: 600,
              py: 0,
              borderRadius: '10px',
              [theme.breakpoints.down('md')]: {
                maxWidth: 600
              },
              '& .MuiListItemSecondaryAction-root': {
                top: 22
              },
              '& .MuiDivider-root': {
                my: 0
              },
              '& .list-container': {
                pl: 7,
                pr: 2
              }
            }}
          >
            {notifications.map((notif, index) => (
              <Fragment key={notif.id}>
                <ListItemWrapper
                  key={index}
                  sx={{ backgroundColor: notif.isRead ? '' : '#f0f4fc', minHeight: 120, maxHeight: 120, px: 2 }}
                  onClick={() => handleNavigate(notif)}
                >
                  <ListItem alignItems="center" justifyContent="center">
                    <ListItemAvatar>
                      <Avatar sx={{ bgcolor: userNotifTemplates[notif.templateType].getColor }}>
                        {userNotifTemplates[notif.templateType].getIcon}
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', py: 0.5 }}>
                          <Typography variant="h5">{userNotifTemplates[notif.templateType].getTitle}</Typography>
                          <Box>
                            <Typography variant="caption">{getDuration(notif.createdAt)}</Typography>
                            {notif.isRead ? (
                              <IconButton
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleMarkAsUnread(notif.id);
                                }}
                                sx={{ zIndex: 200, verticalAlign: 'middle' }}
                              >
                                <Tooltip title="Mark as Unread">
                                  <TonalityIcon sx={{ fontSize: 11 }} color="primary" />
                                </Tooltip>
                              </IconButton>
                            ) : (
                              <IconButton
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleMarkAsRead(notif.id);
                                }}
                                sx={{ zIndex: 200 }}
                              >
                                <Tooltip title="Mark as Read">
                                  <CircleIcon sx={{ fontSize: 11 }} color="primary" />
                                </Tooltip>
                              </IconButton>
                            )}
                          </Box>
                        </Box>
                      }
                      disableTypography
                    />
                  </ListItem>
                  <Grid container direction="column" className="list-container">
                    <Grid item xs={12} sx={{ pb: 2 }}>
                      <Typography variant="body2" sx={{ mr: 3 }}>
                        {notif.message}
                      </Typography>
                    </Grid>
                  </Grid>
                </ListItemWrapper>
                <Divider />
              </Fragment>
            ))}
          </List>
        </>
      ) : (
        <Grid
          sx={{
            // width: '100%',
            width: 600,
            py: 0,
            borderRadius: '10px',
            [theme.breakpoints.down('md')]: {
              maxWidth: 500
            }
          }}
        >
          <Grid item xs={12} sx={{ height: 150, width: 430 }} alignItems="center">
            <Typography variant="h5" sx={{ p: 2, mt: 1 }}>
              No unread notifications
            </Typography>
            <Typography variant="body1" sx={{ px: 2, mt: -1 }}>
              You have read all the notifications.
            </Typography>
          </Grid>
        </Grid>
      )}
    </>
  );
};

NotificationListModal.propTypes = {
  notifications: PropTypes.array,
  getUnreadNotifCount: PropTypes.func,
  handleClose: PropTypes.func
};

export default NotificationListModal;
