import PropTypes from 'prop-types';

// MUI
import { Box } from '@mui/material';

// assets
import logoLoader from 'assets/images/logo_loader.png';

// =========================== || BrandLogoLoader || =========================== //

const BrandLogoLoader = ({ width }) => (
  <Box
    component="img"
    src={logoLoader}
    alt="logo loader"
    sx={{
      width,
      '@keyframes spin': {
        from: {
          transform: 'rotate(0deg)'
        },
        to: {
          transform: 'rotate(360deg)'
        }
      },
      animation: `spin linear 1.3s infinite`
    }}
  />
);

BrandLogoLoader.propTypes = {
  width: PropTypes.number.isRequired
};

export default BrandLogoLoader;
