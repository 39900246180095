// axios
import axios from 'axios';

const functionConfig = {
  baseURL: import.meta.env.VITE_APP_AZ_FUNCTIONS_BASE_URI,
  withCredentials: true
};

/**
 * Makes a request to an azure function for authentication.
 * @param {object} requestConfig Axios request config. Only the `url` is required. For all available options see https://axios-http.com/docs/req_config.
 */
const callAzureFunctionAuth = async (requestConfig) => {
  const axiosResponse = await axios({
    ...requestConfig,
    ...functionConfig
  });

  return axiosResponse;
};

export default callAzureFunctionAuth;
